import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Text } from '../Text'
import { CodeInput } from '../CodeInput'
import { Button } from '../Button'

const STEPS = {
    phone: 'teléfono',
    email: 'email',
}

const ConfirmCode = ({ pending = false, excededAttempts = false, step, fields = 6, value, error = '', onChange, onSuccess }) => {
    return (
        <>
            <div className='textCenter'>
                <Text size="md" color="gray">
                    Ingresa a tu {STEPS[step]} para que podamos verificar tu cuenta.
                </Text>
                <div className='box-gray'>
                    <Text size="sm" color="primary">
                        {value}
                    </Text>
                </div>
                {!excededAttempts &&
                <CodeInput fields={fields} onChange={onChange} />
                }
                {error && <p className='inputCustom_error' style={{ marginTop: 10 }}>{error}</p>}
            </div>

            <div className={clsx('dflex', 'justify-center')}>
                <Button pending={pending} onClick={onSuccess} size="xl" rounded>
                    { excededAttempts ? 'Cerrar' : 'Verificar codigo' }
                </Button>
            </div>
        </>
    )
}

export default ConfirmCode

ConfirmCode.propTypes = {
    step: PropTypes.string,
    fields: PropTypes.number,
    value: PropTypes.string,
    error: PropTypes.string,
    pending: PropTypes.bool,
    excededAttempts: PropTypes.bool,
    onChange: PropTypes.func,
    onSuccess: PropTypes.func,
}