import React from 'react'
import PropType from 'prop-types'

// Components
import { FeaturedContainer } from '../FeaturedContainer'
import { LotList } from '../LotList'

const LotFeatured = ({ title, description, listLot }) => {
    return (
        <FeaturedContainer title={title} description={description}>
            <LotList data={listLot} />
        </FeaturedContainer>
    )
}

LotFeatured.propTypes = {
    title: PropType.string,
    description: PropType.string,
    listLot: PropType.array,
}

export default LotFeatured
