import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Text } from '../Text'

const ShowFeatured = ({
    showItems,
    data,
    showDiferent,
    showDetailDiferent,
    transField,
}) => {
    return (
        <div className='basicInfo_list'>
            {showItems.map((item) => (
                <div key={item} className='basicInfo_list_item'>
                    <Text bold>{transField[item] || item}</Text>
                    {showDiferent.includes(item)
                        ? showDetailDiferent({
                            field: item,
                        })
                        : data[item]}
                </div>
            ))}
        </div>
    )
}

ShowFeatured.propTypes = {
    showItems: PropTypes.array,
    data: PropTypes.object,
    showDiferent: PropTypes.array,
    showDetailDiferent: PropTypes.func,
    transField: PropTypes.object,
}

export default ShowFeatured
