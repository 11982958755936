import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Text } from '../Text'

// Utils
import { formatMoney } from '../../utils/helpers'

const FeaturedPropertiesPrice = ({ price, size = 'xl', text }) => {
    return (
        <div className='featuredPropertiesPrice'>
            <Text size={size} color='primary'>
                {
                    price && (<>{`$ ${formatMoney({ number: price })}`}</>)
                }
                {
                    text && (<>{text}</>)
                }
            </Text>
        </div>
    )
}

FeaturedPropertiesPrice.propTypes = {
    price: PropTypes.string,
    size: PropTypes.string,
    text: PropTypes.string,
}

export default FeaturedPropertiesPrice
