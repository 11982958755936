import * as React from 'react'

const GoogleLogo = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.49 24'>
        <path
            d='M23.49 12.27A13.9 13.9 0 0 0 23.3 10H12v4.51h6.47a5.571 5.571 0 0 1-2.4 3.58v3h3.86a11.733 11.733 0 0 0 3.56-8.82Z'
            fill='#4285f4'
        />
        <path
            d='M12 24a11.431 11.431 0 0 0 7.93-2.91l-3.86-3A7.239 7.239 0 0 1 12 19.25a7.167 7.167 0 0 1-6.73-4.96H1.29v3.09A11.982 11.982 0 0 0 12 24Z'
            fill='#34a853'
        />
        <path
            d='M5.27 14.29A6.963 6.963 0 0 1 4.89 12a7.234 7.234 0 0 1 .38-2.29V6.62H1.29A11.861 11.861 0 0 0 0 12a11.861 11.861 0 0 0 1.29 5.38Z'
            fill='#fbbc05'
        />
        <path
            d='M12 4.75a6.511 6.511 0 0 1 4.6 1.8l3.42-3.42A11.473 11.473 0 0 0 12 0 11.982 11.982 0 0 0 1.29 6.62l3.98 3.09A7.167 7.167 0 0 1 12 4.75Z'
            fill='#ea4335'
        />
    </svg>
)

export default GoogleLogo
