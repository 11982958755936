import React, { useState, useEffect, useContext } from 'react'

// Hooks
import { usePagination } from '../../hooks'

// Components
import { Title } from '../../components/Title'
import { Pagination } from '../../components/Pagination'
import { ListFavorite } from './Components/ListFavorite'
import Context from '../../Context/UserContext'
import {
    LoadingFavorites,
} from '../../components/Loading'

const LIMIT_ITEM_FOR_PAGE = 5

const DashboardFavorite = () => {
    const { favorites, pending: loading } = useContext(Context)

    const [listFeatured, setLisfeatured] = useState([])
    const { page, handleChangePage, totalPage, setTotalPage } = usePagination({
        data: favorites,
        itemForPage: LIMIT_ITEM_FOR_PAGE,
    })

    useEffect(() => {
        const data = favorites.slice(
            (page - 1) * LIMIT_ITEM_FOR_PAGE,
            (page - 1) * LIMIT_ITEM_FOR_PAGE + LIMIT_ITEM_FOR_PAGE,
        ).map((item) => ({ ...item, interested: [] } ))
        setLisfeatured(
            data,
        )
    }, [page, favorites])

    useEffect(() => {
        setTotalPage(Math.ceil(favorites.length / LIMIT_ITEM_FOR_PAGE))
    }, [favorites])

    return (
        <div className='dashboardPage'>
            <Title size='lg'>Favoritos</Title>

            { loading ? (
                <LoadingFavorites count={3} />
            ) : (
                <>
                    <ListFavorite data={listFeatured}/>

                    { listFeatured.length > 0 &&
                    <Pagination
                        page={page}
                        countPage={totalPage}
                        onChangePage={handleChangePage}
                    />
                    }
                </>
            )}
        </div>
    )
}

export default DashboardFavorite