import * as React from 'react'
import PropTypes from 'prop-types'

const WhatsappIcon = ({ color = '#69A831' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 70'>
        <g transform='translate(-.333)'>
            <g fill='none' stroke={color} strokeLinecap='round' strokeWidth={1.5}>
                <path
                    d='M37.042 49.593a13.516 13.516 0 1 0-12.214-7.723l-3.232 7.723 8.48-1.931a13.452 13.452 0 0 0 6.966 1.931Z'
                    strokeLinejoin='round'
                />
                <path
                    d='M33.26 34.274a1.995 1.995 0 1 1 2.012-1.994 1.995 1.995 0 0 1-2.012 1.994ZM38.813 39.781a2.01 2.01 0 1 0 2.012-1.995 2.01 2.01 0 0 0-2.012 1.995Z'
                    strokeLinejoin='round'
                />
                <path d='M31.25 32.28c-.027 6.694 6.372 10.227 9.575 9.5' />
            </g>
        </g>
    </svg>
)

WhatsappIcon.propTypes = {
    color: PropTypes.string,
}

export default WhatsappIcon
