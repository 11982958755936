import React from 'react'

// Components
import { PlacesAndSectorsCard } from './'
import PropTypes from 'prop-types'

// Utils
import { FIELDS_PLACES_OF_INTEREST } from '../../utils/fields'

const PlacesAndSectorsList = ({ data }) => {
    return (
        <div className='placesAndSectors_list'>
            {data.map((item) => (
                <PlacesAndSectorsCard
                    key={item.id}
                    id={item.id}
                    location={
                        item?.attributes[FIELDS_PLACES_OF_INTEREST.location]?.data?.attributes
                            ?.Nombre || ''
                    }
                    image={
                        item?.attributes[FIELDS_PLACES_OF_INTEREST.imgPrimary]?.data
                            ?.attributes?.url || ''
                    }
                />
            ))}
        </div>
    )
}

PlacesAndSectorsList.propTypes = {
    data: PropTypes.array,
}

export default PlacesAndSectorsList
