import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Text } from '../../../../components/Text'

const PropertyGalery = ({ imgPrimary, galery, onOpenGalery }) => {
    return (
        <div className='propertyGalery'>
            <div className='propertyGalery_first'>
                <img
                    src={imgPrimary.data.attributes.url}
                    alt='img'
                    onClick={onOpenGalery}
                />
            </div>

            {galery?.data.length > 0 && (
                <>
                    {galery.data.slice(0, 3).map((item, index) => {
                        const { url, name } = item.attributes

                        if (galery.data.slice(0, 3).length - 1 === index)
                            return (
                                <div
                                    key={item.id}
                                    className='propertyGalery_last'
                                    onClick={onOpenGalery}
                                >
                                    <img src={url} alt={name} />
                                    <Text classNameCustom='propertyGalery_last_text'>
                                        + {galery.data.length}
                                    </Text>
                                </div>
                            )

                        return (
                            <div className='propertyGalery_subImage' key={item.id}>
                                <img
                                    onClick={onOpenGalery}
                                    src={url}
                                    alt={name}
                                />
                            </div>
                        )
                    })}
                </>
            )}
        </div>
    )
}

PropertyGalery.propTypes = { 
    imgPrimary: PropTypes.object, 
    galery: PropTypes.object, 
    onOpenGalery: PropTypes.func,
}

export default PropertyGalery
