import React from 'react'

// Components
import { Title } from '../../components/Title'
import {
    LoadingFavorites,
} from '../../components/Loading'

import { useFetch } from '../../hooks'
import UserPropertySale from '../../components/UserPropertySale/UserPropertySale'

const DashboardProperties = () => {

    const { data, loading } = useFetch({ endpoint: 'user-properties' })
    const [properties, setProperties] = React.useState([])

    React.useEffect(() => {
        if (data.data) setProperties(data.data.map(({ id, attributes }) => ({ id, ...attributes })))
    }, [data])

    return (
        <div className='dashboardPage'>
            <Title size='lg'>Propiedades en venta</Title>

            { loading ? (
                <LoadingFavorites count={3} />
            ) : (
                properties.map((item) => <UserPropertySale key={item.id} item={item} />)
            )}
        </div>
    )
}

export default DashboardProperties