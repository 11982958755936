import React from 'react'
import { Outlet } from 'react-router-dom'
import { Hub } from 'aws-amplify'
import UserContext from '../../Context/UserContext'

// Components
import { Header } from '../Header'
import { Footer } from '../Footer'

const Layout = () => {

    const { getUser } = React.useContext(UserContext)

    React.useEffect(() => {
        getUser()
    }, [])

    React.useEffect(() => {
        Hub.listen('auth', async ({ payload }) => {
            if (payload.event === 'signIn' || payload.event === 'signIn_failure') {
                await getUser()
            }
        })
        return () => {
            return Hub.remove('auth')
        }
    }, [])

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default Layout
