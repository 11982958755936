import React from 'react'
import PropTypes from 'prop-types'

const Title = ({
    children,
    size,
    color = 'black',
    center,
    customClass = '',
}) => {
    const sizeElements = {
        xl: 'h1',
        lg: 'h2',
        md: 'h3',
        sm: 'h4',
        xs: 'h5',
    }

    return React.createElement(
        sizeElements[size] || 'h1',
        {
            className: `textColor_${color} ${
                center ? 'textCenter' : ''
            } ${customClass}`,
        },
        children,
    )
}

Title.propTypes = {
    children: PropTypes.node,
    size: PropTypes.string,
    color: PropTypes.string,
    center: PropTypes.bool,
    customClass: PropTypes.string,
}

export default Title