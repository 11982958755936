import * as React from 'react'
import PropTypes from 'prop-types'

const FacebookLogo = ({ color = '#2f55a4' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <path fill='none' d='M0 0h24v24H0z' />
        <path
            d='M22 12a10 10 0 1 0-12 9.8V15H8v-3h2V9.5A3.5 3.5 0 0 1 13.5 6H16v3h-2a1 1 0 0 0-1 1v2h3v3h-3v6.95A10 10 0 0 0 22 12Z'
            fill={color}
        />
    </svg>
)

FacebookLogo.propTypes = {
    color: PropTypes.string,
}

export default FacebookLogo
