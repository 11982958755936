import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../components/Title'
import { Text } from '../../../../components/Text'

const AboutSection = ({
    subTitle,
    title,
    description,
    image,
    revertDirection,
    sectionIcon,
}) => {
    const classSection = clsx('aboutSection', {
        'aboutSectionRivert': revertDirection,
    })
    return (
        <div className={classSection}>
            <div className='aboutSection_content'>
                <Title size='xs'>{subTitle}</Title>
                <Title size='lg'>{title}</Title>
                <Text color='gray'>{description}</Text>
                {sectionIcon && <div className='aboutSection_icons'>{sectionIcon}</div>}
            </div>

            <img src={image} alt='Sobre nosotros' />
        </div>
    )
}

AboutSection.propTypes = {
    subTitle: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    revertDirection: PropTypes.bool,
    sectionIcon: PropTypes.node,
}

export default AboutSection
