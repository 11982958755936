import {
    FIELD_KEY_ADMINISTRATION,
    FIELD_KEY_BATHROOMS,
    FIELD_KEY_TOTAL_AREA,
    FIELD_KEY_BUILT_AREA,
    FIELD_KEY_LOCATION,
    FIELD_KEY_ANTIQUITY,
    FIELD_KEY_CONTANT_PHONE,
    FIELD_KEY_PROPERTY_CODE,
    FIELDS_PROPERTY,
    FIELD_PARCELLING,
    FIELD_LOTES,
} from './fields'

export const TRANS_FIELDS = {
    [FIELDS_PROPERTY[FIELD_KEY_BATHROOMS]]: 'Baños',
    [FIELDS_PROPERTY[FIELD_KEY_TOTAL_AREA]]: 'Área Total',
    [FIELDS_PROPERTY[FIELD_KEY_BUILT_AREA]]: 'Área Construida',
    [FIELDS_PROPERTY[FIELD_KEY_ANTIQUITY]]: 'Antigüedad',
    [FIELDS_PROPERTY[FIELD_KEY_LOCATION]]: 'Ubicación',
    [FIELDS_PROPERTY[FIELD_KEY_CONTANT_PHONE]]: 'Teléfono de Contacto',
    [FIELDS_PROPERTY[FIELD_KEY_ADMINISTRATION]]: 'Administración',
    [FIELDS_PROPERTY[FIELD_KEY_PROPERTY_CODE]]: 'Código Propiedad',
    [FIELDS_PROPERTY.area_lote]: 'Área Lote',
    [FIELDS_PROPERTY.via_acceso]: 'Vía de acceso',
    [FIELDS_PROPERTY.porteria]: 'Portería',
    [FIELDS_PROPERTY.topography]: 'Topografía',
}

export const TRANS_FIELDS_PARCELLING = {
    [FIELD_PARCELLING.area]: 'Áreas',
    [FIELD_PARCELLING.energy]: 'Energía',
    [FIELD_PARCELLING.socialArea]: 'Áreas sociales',
    [FIELD_PARCELLING.specialArea]: 'Áreas especiales',
}

export const TRANS_FIELDS_LOTES = {
    [FIELD_LOTES.lot_area]: 'Áreas',
    [FIELD_LOTES.topography]: 'Topografía',
    [FIELD_LOTES.goal]: 'Portería',
    [FIELD_LOTES.areaDozing]: 'Área explanación',
    [FIELD_LOTES.administration]: 'Administación',
}