import React from 'react'

// Components
import { TitleIcon } from '../Title'

// Icons
import { FilterIcon } from '../Svg'

const FilterTitle = () => {
    return <TitleIcon icon={<FilterIcon />} title='Filtros' />
}

export default FilterTitle
