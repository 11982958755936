import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'

const RenderMarkDown = ({ children, color = 'black' }) => {
    return <ReactMarkdown className={`markDown textColor_${color}`}>{children}</ReactMarkdown>
}

RenderMarkDown.propTypes = {
    children: PropTypes.node,
    color: PropTypes.string,
}

export default RenderMarkDown
