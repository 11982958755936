import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Text } from '../Text'

// Icons
import { LocationIcon, LocationFullIcon } from '../Svg'

const FeaturedPropertiesLocation = ({ location, size, iconFull, color = 'gray' }) => (
    <Text
        size={size}
        color={color}
        classNameCustom={`textLocation textLocation_svg${size}`}
    >
        {iconFull ? <LocationFullIcon /> : <LocationIcon color='#69A831' />}
        {location}
    </Text>
)


FeaturedPropertiesLocation.propTypes = {
    location: PropTypes.string,
    size: PropTypes.string,
    iconFull: PropTypes.bool,
    color: PropTypes.string,
}

export default FeaturedPropertiesLocation
