import React from 'react'
import GoogleMapReact from 'google-map-react'
import PropTypes from 'prop-types'

// Images
import PinImage from '../../assets/images/pin.png'

const AnyReactComponent = () => (
    <img className='googleMaps' src={PinImage} alt='pin' />
)

const Maps = ({ lat, lng, zoom = 17 }) => {
    const defaultProps = {
        center: {
            lat: Number(lat) || 10.476354,
            lng: Number(lng) || -66.552322,
        },
        zoom,
    }

    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
        >
            <AnyReactComponent lat={Number(lat)} lng={Number(lng)} />
        </GoogleMapReact>
    )
}

Maps.propTypes = { 
    lat: PropTypes.string, 
    lng: PropTypes.string, 
    zoom: PropTypes.number,
}

export default Maps
