import * as React from 'react'
import PropTypes from 'prop-types'

const LinkIcon = ({ color = '#69A831' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 31'>
        <defs>
            <clipPath>
                <rect width={31} height={31} rx={6} fill='#f79d4e' />
            </clipPath>
        </defs>
        <g clipPath='url(#a)'>
            <rect width={31} height={31} rx={6} fill={color} />
            <path
                d='M14.728 11.727 17 9.455a3.213 3.213 0 0 1 4.544 4.544l-2.272 2.272m-3.029 3.031-2.272 2.271a3.213 3.213 0 0 1-4.544-4.543l2.272-2.272m6.06-1.517-4.544 4.544'
                fill='none'
                stroke='#fff'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={1.5}
                data-name='Xnix/Line/Link'
            />
        </g>
    </svg>
)

LinkIcon.propTypes = {
    color: PropTypes.string,
}

export default LinkIcon
