import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Spinner from '../Spinner/Spinner'

const Button = ({
    color = 'primary', // color de fondo del boton
    children, // contenido del boton
    to, // indicar que sera un link y no un boton
    rounded, // poner border redondeados
    alignSelfCenter, // centrar todo el boton
    alignSelfStart, // poner el boton al inicio
    className, // clase custom para el boton
    size = 'lg', // tamaño del boton
    icon, // si el boton tiene icono pasar este dato
    underline, // si quieres que el texto tengo una linea abajo
    onClick, // evento click
    transparent, // hacer que el boton no tenga color de fondo
    textColor = 'white', // color de texto
    jContentStart, // justify content center
    type = 'button',
    fontWeight = '500',
    disabled = false,
    pending = false,
}) => {
    const sizeElements = {
        xl: 'btn_size_xl',
        lg: 'btn_size_lg',
        md: 'btn_size_md',
    }

    const classBtn = clsx(
        'btnGeneral',
        className,
        `btn_${color}`,
        sizeElements[size],
        `textColor_${textColor}`,
        {
            'font-600': fontWeight === '600',
            'font-bold': fontWeight === 'bold',
            'rounded': rounded,
            'alignSelfCenter': alignSelfCenter,
            'alignSelfStart': alignSelfStart,
            'btn_icon': icon,
            'btn_underline': underline,
            'btn_transparent': transparent,
            'btn_content_start': jContentStart,
        },
    )

    if (to) {
        return (
            <Link to={to} className={classBtn}>
                {children}
            </Link>
        )
    }

    return (
        <button disabled={disabled || pending} type={type} className={classBtn} onClick={onClick}>
            { pending && <Spinner /> }
            {children}
        </button>
    )
}

Button.propTypes = {
    disabled: PropTypes.bool,
    color: PropTypes.string,
    children: PropTypes.node,
    to: PropTypes.string,
    rounded: PropTypes.bool,
    alignSelfCenter: PropTypes.bool,
    alignSelfStart: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.string,
    icon: PropTypes.bool,
    underline: PropTypes.bool,
    onClick: PropTypes.func,
    transparent: PropTypes.bool,
    textColor: PropTypes.string, // color de texto
    jContentStart: PropTypes.bool,
    type: PropTypes.string,
    fontWeight: PropTypes.string,
    pending: PropTypes.bool,
}

export default Button
