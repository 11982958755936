import React from 'react'
import ContentLoader from 'react-content-loader'

const LoadingHome = () => (
    <ContentLoader viewBox='0 0 850 400'>
        <rect x='0' y='0' width='850' height='400' />
    </ContentLoader>
)

export default LoadingHome
