import * as React from 'react'
import PropTypes from 'prop-types'

const SaveIcon = ({ color, active }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.523 18.501'>
        <path
            d='M12.761 16.603V2.784a2.017 2.017 0 0 0-2-2.034h-8a2.017 2.017 0 0 0-2 2.034v13.819a1 1 0 0 0 1.6.913l3.8-3.281a.9.9 0 0 1 1.206 0l3.794 3.282a1 1 0 0 0 1.6-.914Z'
            fill={active ? color : 'none'}
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={1.5}
        />
    </svg>
)

SaveIcon.propTypes = {
    color: PropTypes.string,
    active: PropTypes.bool,
}

export default SaveIcon
