import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

// Libreria para slider
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
// fin libreria para slider

// Icons
import { ArrowLeftIcon, ArrowRightIcon } from '../../../../components/Svg'

const SliderGalery = ({ galery, close }) => {
    const [page, setPage] = useState(0)
    const swiper = useRef(null)

    const paginate = (getPage) => {
        if (getPage > galery.length - 1) {
            swiper.current.swiper.slideTo(0)
            return setPage(0)
        } else if (getPage < 0) {
            swiper.current.swiper.slideTo(galery.length - 1)
            return setPage(galery.length - 1)
        }

        swiper.current.swiper.slideTo(getPage)
        setPage(getPage)
    }

    return (
        <div className='modalSlider'>
            <div className='modalSliderGalery_close' onClick={close}>
                x
            </div>
            <div className='modalSliderGalery_imgPrimary'>
                <img src={galery[page].attributes.url} alt='images slider' />
                <div className='next' onClick={() => paginate(page + 1)}>
                    <ArrowRightIcon />
                </div>
                <div className='prev' onClick={() => paginate(page - 1)}>
                    <ArrowLeftIcon />
                </div>
            </div>

            <Swiper
                ref={swiper}
                slidesPerView={3}
                spaceBetween={30}
                freeMode={true}
                modules={[FreeMode, Pagination]}
                className='mySwiper'
            >
                {galery.map((item, index) => (
                    <SwiperSlide
                        key={item.id}
                        className={page === index ? 'sliderActive' : ''}
                        onClick={() => paginate(index)}
                    >
                        <img src={item.attributes.url} alt='img' />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

SliderGalery.propTypes = {
    galery: PropTypes.array,
    close: PropTypes.func,
}

export default SliderGalery
