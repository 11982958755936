import React from 'react'
import PropTypes from 'prop-types'

// Componetns
import { Text } from '../Text'

// Icon
import { GoogleLogo } from '../Svg'

const ButtonGoogle = ({ textShort, onClick }) => {
    return (
        <button onClick={onClick} className='buttonGoogle'>
            <GoogleLogo />
            <Text>{textShort ? 'Google' : 'Registro con google'}</Text>
        </button>
    )
}

ButtonGoogle.propTypes = {
    textShort: PropTypes.bool,
    onClick: PropTypes.func,
}

export default ButtonGoogle
