import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../components/Title'
import {
    FeaturedPropertiesLocation,
    FeaturedPropertiesPrice,
} from '../../../../components/FeaturedPropertiesList'
import { SocialShare } from '../../../../components/SocialShare'

const PropertyHeader = ({ name, code = '', location, price }) => (
    <div className='propertyHeader'>
        <div className='propertyHeader_name'>
            <Title size='md'>
                {name}
                <span className='propertyHeader_code'>{code}</span>
            </Title>

            <SocialShare color='gray' text='Compartir' link face whats />
        </div>
        <FeaturedPropertiesLocation size='xl' location={ location } iconFull />
        <FeaturedPropertiesPrice price={price} />
    </div>
)


PropertyHeader.propTypes = {
    name: PropTypes.string,
    code: PropTypes.string,
    location: PropTypes.string,
    price: PropTypes.string,
}

export default PropertyHeader
