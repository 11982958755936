import React from 'react'
import PropTypes from 'prop-types'
import Context from '../../Context/UserContext'
import { API_PRIVATE } from '../../service/api'
import { toast } from 'react-toastify'

// Icons
import { SaveIcon } from '../Svg'

const SavePropertie = ({ item }) => {

    const { favorites, setFavorites } = React.useContext(Context)
    const [isFavorite, setIsFavorite] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const addRemoveToFavorites = async (ev, item) => {
        try {
            ev.preventDefault()

            if (loading) return
            const body = isFavorite ? { id: item.id } : item
            const slugUrl = isFavorite ? 'me/remove-favorite' : 'me/add-favorite'
            const method = isFavorite ? 'DELETE' : 'POST'
            setLoading(true)

            API_PRIVATE({ endpoint: slugUrl, method, body }).then(() => {
                if (isFavorite) {
                    setFavorites(favorites.filter(favorite => favorite.id !== item.id))
                } else {
                    setFavorites([...favorites, item])
                }
            }).catch((err) => {
                console.log(err)
                toast.error('Ha ocurrido un error, intenta más tarde')
            }).finally(() => setLoading(false))
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        setIsFavorite(favorites.some((favorite) => favorite.id === item.id))
    }, [item, favorites])

    return (
        <div className='savePropertie' onClick={(ev) => addRemoveToFavorites(ev, item)}>
            {loading ? <span>...</span> : <SaveIcon color='#69A831' active={isFavorite} /> }
        </div>
    )
}

SavePropertie.propTypes = {
    item: PropTypes.object,
}

export default SavePropertie
