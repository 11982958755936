import React from 'react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'

// Components
import { Title } from '../Title'

function Modal({ children, onClose, className, header, direction = 'center' }) {
    const ModalContentClass = clsx('modal_content', className, {
        'modal_content_max_height': direction !== 'center',
    })
    const ModalClass = clsx('modal', `modal_direction_${direction}`)

    return (
        <div className={ModalClass}>
            <div className={ModalContentClass}>
                <button type='button' className='modal_close' onClick={onClose}>
                    x
                </button>
                {header && (
                    <Title size='sm' customClass='modal_header'>
                        {header}
                    </Title>
                )}
                {children}
            </div>
            <button
                type='button'
                onClick={onClose}
                className='modal_window'
                aria-label='Close Modal'
            />
        </div>
    )
}

Modal.propTypes = { 
    children: PropTypes.node, 
    onClose: PropTypes.func, 
    className: PropTypes.string, 
    header: PropTypes.string, 
    direction: PropTypes.string,
}

export default function ModalMirror({
    children,
    onClose,
    className,
    header,
    direction,
}) {
    return ReactDOM.createPortal(
        <Modal
            onClose={onClose}
            className={className}
            header={header}
            direction={direction}
        >
            {children}
        </Modal>,
        document.getElementById('modal-root'),
    )
}
