import * as React from 'react'
import PropTypes from 'prop-types'

const WhatsappShareIcon = ({ color = '#69A831' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 30'>
        <g transform='translate(-.035)'>
            <rect
                width={31}
                height={30}
                rx={6}
                transform='translate(.035)'
                fill={color}
            />
            <g fill='none' stroke='#fff' strokeLinecap='round' strokeWidth={1.5}>
                <path
                    d='M15.855 21.245a5.79 5.79 0 1 0-5.232-3.309l-1.385 3.309 3.633-.827a5.763 5.763 0 0 0 2.984.827Z'
                    strokeLinejoin='round'
                />
                <path
                    d='M14.235 14.683a.854.854 0 1 1 .862-.854.854.854 0 0 1-.862.854ZM16.614 17.042a.861.861 0 1 0 .862-.854.861.861 0 0 0-.862.854Z'
                    strokeLinejoin='round'
                />
                <path d='M13.374 13.828c-.012 2.867 2.73 4.381 4.1 4.067' />
            </g>
        </g>
    </svg>
)

WhatsappShareIcon.propTypes = {
    color: PropTypes.string,
}

export default WhatsappShareIcon
