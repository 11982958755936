import * as React from 'react'
import PropTypes from 'prop-types'

const FacebookIcon = ({ color = '#69A831' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 71 70'>
        <path
            d='M53.415 36.079a16.4 16.4 0 1 0-19.681 16.07V40.998h-3.28v-4.919h3.28v-4.1a5.746 5.746 0 0 1 5.74-5.74h4.1v4.92h-3.28a1.645 1.645 0 0 0-1.64 1.64v3.28h4.92v4.92h-4.92v11.4a16.4 16.4 0 0 0 14.761-16.32Z'
            fill={color}
        />
    </svg>
)

FacebookIcon.propTypes = {
    color: PropTypes.string,
}

export default FacebookIcon
