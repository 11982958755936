import React from 'react'
import PropTypes from 'prop-types'

// Components
import { FeaturedPropertiesLarge } from '../FeaturedPropertiesLarge'

const FeaturedPropertiesLargeList = ({ data, shadow }) => {
    return (
        <>
            {data.map((item, index) => (
                <FeaturedPropertiesLarge
                    key={index}
                    id={item.id}
                    name={item.name}
                    details={{
                        bed: item.bed,
                        bathroom: item.bathroom,
                        meters: item.meters,
                    }}
                    location={item.location}
                    price={item.price}
                    image={item.image}
                    shadow={shadow}
                    favorite={item.save}
                    interested={item.interested}
                />
            ))}
        </>
    )
}

FeaturedPropertiesLargeList.propTypes = {
    data: PropTypes.array,
    shadow: PropTypes.bool,
}

export default FeaturedPropertiesLargeList
