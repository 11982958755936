import React from 'react'
import PropTypes from 'prop-types'

const LoadingContainer = ({ col, children }) => {
    return (
        <div className='container'>
            <div className='content'>
                <div className={`loadingContainer_${col}`}>{children}</div>
            </div>
        </div>
    )
}

LoadingContainer.propTypes = { 
    col: PropTypes.number, 
    children: PropTypes.node,
}

export default LoadingContainer
