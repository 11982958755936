import React from 'react'
import PropTypes from 'prop-types'

// components
import { Text } from '../Text'

const PropertiesInterested = ({ image, user, email }) => {
    return (
        <div className='propertiesInterested'>
            <img src={image} alt={user} />
            <div className='propertiesInterested_text'>
                <Text color='primary' bold size='sm'>
                    {user}
                </Text>
                <Text color='primary' size='sm'>
                    {email}
                </Text>
            </div>
        </div>
    )
}

PropertiesInterested.propTypes = {
    image: PropTypes.string,
    user: PropTypes.string,
    email: PropTypes.string,
}

export default PropertiesInterested
