import React from 'react'
import ContentLoader from 'react-content-loader'

const LoadingDetailProperty = () => (
    <ContentLoader
        viewBox='0 0 850 900'
        backgroundColor='#eaeced'
        foregroundColor='#ffffff'
    >
        <rect x='42' y='57' rx='4' ry='4' width='417' height='29' />
        <rect x='42' y='105' rx='4' ry='4' width='67' height='15' />
        <rect x='127' y='105' rx='4' ry='4' width='147' height='15' />
        <circle cx='781' cy='63' r='9' />
        <circle cx='811' cy='63' r='9' />
        <circle cx='841' cy='63' r='9' />
        <rect x='42' y='157' rx='4' ry='4' width='630' height='320' />
        <rect x='700' y='157' rx='4' ry='4' width='150' height='100' />
        <rect x='700' y='267' rx='4' ry='4' width='150' height='100' />
        <rect x='700' y='377' rx='4' ry='4' width='150' height='100' />
        <rect x='48' y='515' rx='4' ry='4' width='720' height='15' />
        <rect x='49' y='547' rx='4' ry='4' width='598' height='15' />
        <rect x='48' y='581' rx='4' ry='4' width='720' height='15' />
        <rect x='49' y='612' rx='4' ry='4' width='520' height='15' />
        <rect x='48' y='652' rx='4' ry='4' width='720' height='15' />
        <rect x='48' y='684' rx='4' ry='4' width='598' height='15' />
        <rect x='48' y='718' rx='4' ry='4' width='720' height='15' />
        <rect x='49' y='748' rx='4' ry='4' width='419' height='15' />
        <rect x='41' y='836' rx='4' ry='4' width='720' height='3' />
    </ContentLoader>
)

export default LoadingDetailProperty
