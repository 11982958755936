import { useState } from 'react'
import { API_PUBLIC } from '../service/api'

const POPULATE = 'populate'
const ENDPOINT = '/inicio'

const useBlog = () => {
    const [dataHome, setDataHome] = useState([])
    const [errorDataHome, setErrorDataHome] = useState('')
    const [loadingDataHome, setLoadingDataHome] = useState(true)

    const getDataHome = async () => {
        try {
            setLoadingDataHome(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}?${POPULATE}=*`,
            })

            setDataHome(res.data.attributes)
        } catch (error) {
            setErrorDataHome(error)
        } finally {
            setLoadingDataHome(false)
        }
    }

    return {
        dataHome,
        errorDataHome,
        loadingDataHome,
        getDataHome,
    }
}

export default useBlog
