import React from 'react'
import PropType from 'prop-types'

// Components
import { FeaturedPropertiesList } from '../FeaturedPropertiesList'
import { FeaturedContainer } from '../FeaturedContainer'

const FeaturedProperties = ({ title, description, listProperty }) => {
    return (
        <FeaturedContainer title={title} description={description}>
            <FeaturedPropertiesList data={listProperty} />
        </FeaturedContainer>
    )
}

FeaturedProperties.propTypes = {
    title: PropType.string,
    description: PropType.string,
    listProperty: PropType.array,
}

export default FeaturedProperties
