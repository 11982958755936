import React from 'react'
import PropTypes from 'prop-types'

const Option = ({ value, children, disabled }) => {
    return (
        <option disabled={disabled} value={value}>
            {children}
        </option>
    )
}

Option.propTypes = {
    value: PropTypes.number,
    children: PropTypes.node,
    disabled: PropTypes.bool,
}

export default Option
