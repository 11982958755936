import React from 'react'
import ContentLoader from 'react-content-loader'

// Components
import { LoadingContainer } from '.'

const LoadingPolicy = () => {
    return (
        <LoadingContainer>
            <ContentLoader viewBox='0 0 700 620'>
                <rect x='0' y='42' rx='5' ry='5' width='100%' height='30' />
                <rect x='0' y='120' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='150' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='180' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='210' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='240' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='270' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='300' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='330' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='360' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='390' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='410' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='440' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='470' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='500' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='530' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='560' rx='5' ry='5' width='100%' height='10' />
                <rect x='0' y='590' rx='5' ry='5' width='100%' height='10' />
            </ContentLoader>
        </LoadingContainer>
    )
}

export default LoadingPolicy
