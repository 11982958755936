import React from 'react'
import PropTypes from 'prop-types'

import ReactCodeInput from 'react-code-input'
import { Text } from '../Text'

const props = {
    inputStyle: {
        margin: '5px',
        MozAppearance: 'textfield',
        width: '42px',
        borderRadius: '4px',
        fontSize: '18px',
        height: '42px',
        backgroundColor: 'white',
        color: '#062828',
        border: '1px solid #cccccc',
        textAlign: 'center',
    },
    inputStyleInvalid: {
        color: 'red',
        border: '1px solid red',
    },
}

const CodeInput = ({ onChange, fields = 6, type = 'number' }) => {

    const onChangeHandler = React.useCallback((value) => onChange && onChange(value), [onChange])

    return (
        <div className='containerCodeInput'>
            <Text size="sm" color="primary" bold>
                Codigo de verificación
            </Text>
            <br />
            <ReactCodeInput
                fields={fields}
                type={type}
                onChange={onChangeHandler}
                {...props}
            />
        </div>
    )
}

export default CodeInput

//Props validation
CodeInput.propTypes = {
    onChange: PropTypes.func,
    fields: PropTypes.number,
    type: PropTypes.string,
}