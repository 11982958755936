import React from 'react'
import ContentLoader from 'react-content-loader'

const LoadingAboutUs = () => (
    <>
        <ContentLoader
            viewBox='0 0 450 160'
            backgroundColor='#f0f0f0'
            foregroundColor='#dedede'
        >
            <rect x='0' y='0' rx='10' ry='0' width='450' height='160' />
        </ContentLoader>
        <ContentLoader viewBox='0 0 400 200' title='Loading news...'>
            <rect x='42.84' y='9.93' rx='0' ry='0' width='143.55' height='88' />
            <rect x='192.84' y='9.67' rx='0' ry='0' width='148.72' height='12.12' />
            <rect x='192.84' y='25.67' rx='0' ry='0' width='148.72' height='9' />
            <rect x='192.84' y='41.41' rx='0' ry='0' width='148.72' height='9' />
            <rect x='192.84' y='57.15' rx='0' ry='0' width='148.72' height='9' />
            <rect x='192.84' y='72.89' rx='0' ry='0' width='25' height='25' />
            <rect x='316' y='72.89' rx='0' ry='0' width='25' height='25' />
            <rect x='255' y='72.89' rx='0' ry='0' width='25' height='25' />

            <rect x='198' y='107' rx='0' ry='0' width='143.55' height='88' />
            <rect x='42.84' y='107' rx='0' ry='0' width='148.72' height='12.12' />
            <rect x='42.84' y='123' rx='0' ry='0' width='148.72' height='9' />
            <rect x='42.84' y='139' rx='0' ry='0' width='148.72' height='9' />
            <rect x='42.84' y='155' rx='0' ry='0' width='148.72' height='9' />
            <rect x='42.84' y='171' rx='0' ry='0' width='25' height='25' />
            <rect x='106' y='171' rx='0' ry='0' width='25' height='25' />
            <rect x='166' y='171' rx='0' ry='0' width='25' height='25' />
        </ContentLoader>
    </>
)

export default LoadingAboutUs
