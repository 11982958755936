import React from 'react'
import { Outlet } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

// Components
import { NavigateDashboard } from './Components/NavigateDashboard'

const DashboardLayout = () => {
    const isTablet = useMediaQuery({
        query: '(max-width: 1200px)',
    })

    return (
        <div className='container'>
            <div className='content dashboardLayout'>
                <div className='dashboardLayout_navigate'>
                    <NavigateDashboard isMobile={isTablet} />
                </div>
                <div className='dashboardLayout_content'>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default DashboardLayout
