import React from 'react'

// Icons
import { LinkIcon } from '../Svg'

// Utils
import { createUrlShare } from '../../utils/helpers'

const LinkShare = () => {

    const handleCopyLink = () => {
        navigator.clipboard.writeText(createUrlShare({ decode: false }))
    }

    return (
        <div className='linkShare' onClick={handleCopyLink}>
            <LinkIcon />
        </div>
    )
}

export default LinkShare
