import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

// Components
import { CardPost } from '../CardPost'

// Routes
import { BLOG_POST_ROUTER } from '../../../../helpers/constants/routes'

// Fileds
import {
    FIELDS_BLOG,
    FIELD_KEY_DESCRIPTION,
    FIELD_KEY_IMG_PRIMARY,
    FILED_KEY_TITLE,
    FIELD_KEY_CREATED,
} from '../../../../utils/fields'

const ListPost = ({ list }) => {
    return (
        <div className='listPost'>
            {list.map((post) => (
                <CardPost
                    key={post.id}
                    image={
                        post.attributes[FIELDS_BLOG[FIELD_KEY_IMG_PRIMARY]].data.attributes
                            .url
                    }
                    title={post.attributes[FIELDS_BLOG[FILED_KEY_TITLE]]}
                    description={post.attributes[FIELDS_BLOG[FIELD_KEY_DESCRIPTION]]}
                    goTo={`${BLOG_POST_ROUTER.path}/${post.id}`}
                    date={moment(post.attributes[FIELDS_BLOG[FIELD_KEY_CREATED]]).format(
                        'DD / MM / YYYY',
                    )}
                />
            ))}
        </div>
    )
}

ListPost.propTypes = {
    list: PropTypes.array,
}

export default ListPost
