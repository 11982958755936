import React, { useCallback, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

// Utils
import { FIELD_KEY_PRICE, FIELD_KEY_TOTAL_AREA } from '../../utils/fields'

// Helpers
import { formatMoney } from '../../utils/helpers'

const InputRange = ({ min, max, onChange, label, type, defaultMax }) => {
    const [minVal, setMinVal] = useState(min)
    const [maxVal, setMaxVal] = useState(defaultMax || max)
    const minValRef = useRef(min)
    const maxValRef = useRef(max)
    const range = useRef(null)

    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max],
    )

    useEffect(() => {
        const minPercent = getPercent(minVal)
        const maxPercent = getPercent(maxValRef.current)

        if (range.current) {
            range.current.style.left = `${minPercent}%`
            range.current.style.width = `${maxPercent - minPercent}%`
        }
    }, [minVal, getPercent])

    useEffect(() => {
        const minPercent = getPercent(minValRef.current)
        const maxPercent = getPercent(maxVal)

        if (range.current) {
            range.current.style.width = `${maxPercent - minPercent}%`
        }
    }, [maxVal, getPercent])

    return (
        <label className='input_range_label'>
            {label}
            <div className='input_range'>
                <input
                    type='range'
                    min={min}
                    max={max}
                    value={minVal}
                    onChange={(event) => {
                        const value = Math.min(Number(event.target.value), maxVal)
                        setMinVal(value)
                        minValRef.current = value
                        onChange({ minV: value, maxV: maxVal })
                    }}
                    className='input_range_thumb input_range_thumb--left'
                    style={{ zIndex: minVal > max - 100 && '5' }}
                />
                <input
                    type='range'
                    min={min}
                    max={max}
                    value={maxVal}
                    onChange={(event) => {
                        const value = Math.max(Number(event.target.value), minVal + 1)
                        setMaxVal(value)
                        maxValRef.current = value
                        onChange({ minV: minVal, maxV: value })
                    }}
                    className='input_range_thumb input_range_thumb--right'
                />

                <div className='input_range_slider'>
                    <div className='input_range_slider__track' />
                    <div ref={range} className='input_range_slider__range' />
                    <div className='input_range_slider__left-value'>
                        {type === FIELD_KEY_PRICE && (
                            <>$ {formatMoney({ number: minVal })}</>
                        )}

                        {type === FIELD_KEY_TOTAL_AREA && (
                            <>
                                {minVal}m<sup>2</sup>
                            </>
                        )}
                    </div>
                    <div className='input_range_slider__right-value'>
                        {type === FIELD_KEY_PRICE && (
                            <>$ {formatMoney({ number: maxVal })}</>
                        )}
                        {type === FIELD_KEY_TOTAL_AREA && (
                            <>
                                {maxVal}m<sup>2</sup>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </label>
    )
}

InputRange.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.string,
    defaultMax: PropTypes.number,
}

export default InputRange
