import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// Components
import { Modal } from '../Modal'
import { FilterTitle } from './'

const FilterMobile = ({ close, children, mobileHiddenOverFlow }) => {
    const classContent = clsx(
        'filterMobile_content', 
        'filterMobile_padding', 
        {
            'hiddenOverflow': mobileHiddenOverFlow,
        })
    return (
        <Modal onClose={close} direction='right' className='filterModal'>
            <div className='filterMobile_padding'>
                <FilterTitle />
            </div>
            <div className={classContent}>
                {children}
            </div>
        </Modal>
    )
}

FilterMobile.propTypes = {
    close: PropTypes.func,
    children: PropTypes.node,
    mobileHiddenOverFlow: PropTypes.bool,
}

export default FilterMobile
