import * as React from 'react'

const NotificationIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 43'>
        <g transform='translate(10141 -7204)'>
            <rect
                width={43}
                height={43}
                rx={6}
                transform='translate(-10141 7204)'
                fill='#69A831'
            />
            <path
                d='M-10119.006 7219.4a5.664 5.664 0 0 0-5.526 5.793v2.317a1.547 1.547 0 0 0 0 3.09h3.193m2.333-11.2v-2.4m0 2.4a5.664 5.664 0 0 1 5.526 5.793v2.317a1.547 1.547 0 0 1 0 3.09h-3.193m-4.667 0a2.435 2.435 0 0 0 .683 1.7 2.288 2.288 0 0 0 3.3 0 2.435 2.435 0 0 0 .683-1.7m-4.667 0h4.668'
                fill='none'
                stroke='#fff'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={1.5}
            />
        </g>
    </svg>
)

export default NotificationIcon
