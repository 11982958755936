import * as React from 'react'

const FlagIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 95 95'>
        <rect
            width={95}
            height={95}
            rx={11.4}
            style={{
                fill: '#69A831',
            }}
        />
        <g>
            <g>
                <path
                    style={{
                        fill: 'none',
                    }}
                    d='M17.11 17.11h60.8v60.8h-60.8z'
                />
                <path
                    d='m54.57 36.42 4.62-5a1.82 1.82 0 0 0 .4-1.9 2.71 2.71 0 0 0-1-1.05 2.46 2.46 0 0 0-1.43-.32H37.52v-1.4a1.14 1.14 0 0 0-2-.79 1.14 1.14 0 0 0-.33.8v41.5a1 1 0 0 0 .33.79 1.07 1.07 0 0 0 .82.35 1.19 1.19 0 0 0 1.14-1.14v-23.6H57a2.28 2.28 0 0 0 1.42-.3 2.52 2.52 0 0 0 1-1 2.15 2.15 0 0 0 0-1.07 2 2 0 0 0-.5-.93Zm-17 5.95v-12H57l-5.54 6.06L57 42.31Z'
                    style={{
                        fill: '#fff',
                    }}
                />
            </g>
        </g>
    </svg>
)

export default FlagIcon
