import React from 'react'
import PropTypes from 'prop-types'

import Context from '../../../../../Context/UserContext'

// Components
import { Input } from '../../../../../components/Input'
import { Title } from '../../../../../components/Title'
import { Button } from '../../../../../components/Button'
import { AutoComplete } from '../../../../../components/Maps'

// Fields
import { FIELD_KEY_ADRRESS } from '../../../../../utils/fields'

const StepOne = ({ goBack, onChange }) => {
    const [dataStepOne, setDataStepOne] = React.useState({
        address: '',
        property_type: '',
        department: '',
        city: '',
        formatted_address: '',
        phone_contact: '',
    })
    const { user } = React.useContext(Context)

    const handleChange = ({ field, value }) => {
        // ! validamos datos
        const update = {
            [field]: value,
        }

        // Actualizamos la ciudad si llega del autocompletado de google
        if (field === FIELD_KEY_ADRRESS) {

            update.formatted_address = value.address

            if (value && value.city) {
                update.department = value.city
            }
            if (value && value.locality) {
                update.city = value.locality
            }
        }

        setDataStepOne({
            ...dataStepOne,
            ...update,
        })
    }

    const handleConfirm = () => {
        // ! validamos que todos los campos esten llenos
        onChange({ data: dataStepOne })
    }

    return (
        <>
            <Title size='sm'>Ingresa los datos de tu propiedad</Title>

            <Input label='Dirección'>
                <AutoComplete
                    placeholder='Dirección de su propiedad'
                    onChange={(value) =>
                        handleChange({ field: FIELD_KEY_ADRRESS, value })
                    }
                />
            </Input>

            <Input
                label='Departamento'
                placeholder='Departamento'
                name='department'
                value={dataStepOne.department}
                disabled
                onChange={(e) =>
                    handleChange({ field: e.target.name, value: e.target.value })
                }
            />

            <Input
                label='Ciudad'
                placeholder='Ciudad'
                name='city'
                value={dataStepOne.city}
                disabled
                onChange={(e) =>
                    handleChange({ field: e.target.name, value: e.target.value })
                }
            />

            <Input
                label='Apartamento, unidad, suite, etc.'
                placeholder='Detalles'
                name='property_type'
                value={dataStepOne.property_type}
                disabled={Boolean(!dataStepOne.address)}
                onChange={(e) =>
                    handleChange({ field: e.target.name, value: e.target.value })
                }
            />

            { !user?.phoneNumber &&
            <Input
                label='Teléfono de contacto'
                placeholder='Ingresa tu teléfono'
                name='phone_contact'
                value={dataStepOne.phone_contact}
                disabled={Boolean(!dataStepOne.address)}
                onChange={(e) =>
                    handleChange({ field: e.target.name, value: e.target.value })
                }
            />
            }

            <div className='modalRegisters_btns'>
                <Button rounded onClick={handleConfirm}>
                    Aceptar
                </Button>
                <Button onClick={goBack} color='grey' textColor='gray' transparent underline>
                    Cancelar
                </Button>
            </div>
        </>
    )
}

StepOne.propTypes = {
    onChange: PropTypes.func,
    goBack: PropTypes.func,
}

export default StepOne
