import React from 'react'
import PropTypes from 'prop-types'

// Component
import { PropertiesCard } from '../FeaturedPropertiesList'

// Utils
import { FIELD_LOTES } from '../../utils/fields'

// Routes
import { LOTE_ROUTER } from '../../helpers/constants/routes'

const LotList = ({ data }) => {
    return (
        <>
            {data.map((item) => (
                <PropertiesCard
                    key={item.id}
                    id={item.id}
                    save={item.attributes.save}
                    name={item.attributes[FIELD_LOTES.name]}
                    location={
                        item.attributes[FIELD_LOTES.location]?.data?.attributes?.Nombre || ''
                    }
                    price={item.attributes[FIELD_LOTES.price]}
                    image={
                        item.attributes[FIELD_LOTES.imgPrimary].data.attributes.url
                    }
                    hiddenDetails
                    goTo={`${LOTE_ROUTER.path}/${item.id}`}
                />
            ))}
        </>
    )
}

LotList.propTypes = {
    data: PropTypes.array,
}

export default LotList
