import { useState } from 'react'
import { API_PUBLIC } from '../service/api'

const ENDPOINT = '/municipios'

const useMunicipality = () => {
    const [dataMunicipality, setDataMunicipality] = useState([])
    const [errorDataMunicipality, setErrorDataMunicipality] = useState('')
    const [loadingDataMunicipality, setLoadingDataMunicipality] = useState(true)

    const getDataMunicipality = async () => {
        try {
            setLoadingDataMunicipality(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}?pagination[limit]=1000`,
            })

            setDataMunicipality(res.data)
        } catch (error) {
            setErrorDataMunicipality(error)
        } finally {
            setLoadingDataMunicipality(false)
        }
    }

    const getOptionForSelect = () => {
        if (dataMunicipality.length === 0) return []

        return dataMunicipality.map(item => ({ label: item.attributes.Nombre || '', value: item.attributes.Nombre || '' }))
    }

    return {
        dataMunicipality,
        errorDataMunicipality,
        loadingDataMunicipality,
        getDataMunicipality,
        getOptionForSelect,
    }
}

export default useMunicipality
