import React, { useEffect } from 'react'

// Components
import { Title, TitleIcon } from '../../components/Title'
import { Text } from '../../components/Text'
import { AboutSection } from './components/AboutSection'
import { LoadingAboutUs } from '../../components/Loading'

// Icons
import {
    FlagIcon,
    FeaturesIcon,
    GroupIcon,
    TargetIcon,
    PhoneIcon,
    KeyIcon,
} from '../../components/Svg'

// Hooks
import { useAboutUs } from '../../hooks'

// Fields
import { FIELD_ABOUT_US } from '../../utils/fields'

// Helpers
import { scrollTop } from '../../helpers/utils'

const AboutUs = () => {
    const { dataAboutUs, loadingDataAboutUs, getDataAboutUs } = useAboutUs()

    useEffect(() => {
        (async () => {
            scrollTop()
            await getDataAboutUs()
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loadingDataAboutUs) return <LoadingAboutUs />

    return (
        <>
            <div className='aboutUs_hero'>
                <div className='aboutUs_hero_content'>
                    <Title color='white' size='lg'>
                        {dataAboutUs[FIELD_ABOUT_US.title_header]}
                    </Title>
                    <Text color='white'>
                        {dataAboutUs[FIELD_ABOUT_US.description_header]}
                    </Text>
                </div>
                <img
                    src={dataAboutUs[FIELD_ABOUT_US.image_header].data.attributes.url}
                    alt='Hero'
                />
            </div>
            <div className='container'>
                <div className='content'>
                    <div className='aboutUs'>
                        <AboutSection
                            subTitle={dataAboutUs[FIELD_ABOUT_US.subTitle_one]}
                            title={dataAboutUs[FIELD_ABOUT_US.title_one]}
                            description={dataAboutUs[FIELD_ABOUT_US.description_one]}
                            image={dataAboutUs[FIELD_ABOUT_US.image_one].data.attributes.url}
                            sectionIcon={
                                <>
                                    <TitleIcon
                                        sizeIcon='xl'
                                        iconTop
                                        icon={<TargetIcon />}
                                        title={dataAboutUs[FIELD_ABOUT_US.feature_one]}
                                    />
                                    <TitleIcon
                                        sizeIcon='xl'
                                        iconTop
                                        icon={<GroupIcon />}
                                        title={dataAboutUs[FIELD_ABOUT_US.feature_two]}
                                    />
                                    <TitleIcon
                                        sizeIcon='xl'
                                        iconTop
                                        icon={<FeaturesIcon />}
                                        title={dataAboutUs[FIELD_ABOUT_US.feature_three]}
                                    />
                                </>
                            }
                        />

                        <AboutSection
                            subTitle={dataAboutUs[FIELD_ABOUT_US.subTitle_two]}
                            title={dataAboutUs[FIELD_ABOUT_US.title_two]}
                            description={dataAboutUs[FIELD_ABOUT_US.description_two]}
                            image={dataAboutUs[FIELD_ABOUT_US.image_two].data.attributes.url}
                            revertDirection
                            sectionIcon={
                                <>
                                    <TitleIcon
                                        sizeIcon='xl'
                                        iconTop
                                        icon={<PhoneIcon />}
                                        title={dataAboutUs[FIELD_ABOUT_US.feature_four]}
                                    />
                                    <TitleIcon
                                        sizeIcon='xl'
                                        iconTop
                                        icon={<KeyIcon />}
                                        title={dataAboutUs[FIELD_ABOUT_US.feature_five]}
                                    />
                                    <TitleIcon
                                        sizeIcon='xl'
                                        iconTop
                                        icon={<FlagIcon />}
                                        title={dataAboutUs[FIELD_ABOUT_US.feature_six]}
                                    />
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs
