import * as React from 'react'
import PropTypes from 'prop-types'

const BathroomIcon = ({ color }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.552 22.985'>
        <path
            d='M2.258 15.724c-.3-1.684.286-1.424 1.623-1.421l15.53-.008c.652-.01.957-.12 1 .591a8.5 8.5 0 0 1-1.02 3.753c-1.567 2.579-4.066 2.247-6.686 2.247-1.509 0-4.822.052-6.132-.079a3.76 3.76 0 0 1-2.976-1.743 5.785 5.785 0 0 1-.587-.941 13.444 13.444 0 0 1-.755-2.4ZM10.31 4.518c-.45.55-2.678 1.285-3.565 1.516-.741.193-.757-.425-.6-.968.278-.953 1.156-1.025 2.038-1.378a1.5 1.5 0 0 1 2.126.831ZM1.493 3.139v9.174c0 1.257.233 1.145-.887 1.219L0 13.711c.221.75.565.524 1.349.687.169.613.13 1.253.274 1.969a9.379 9.379 0 0 0 .526 1.8 9.14 9.14 0 0 0 1.948 2.723c-.544.92-1.227 1.805-.609 2.069.671.285.789-1.6 1.687-1.6.573 0 1.272.287 2.234.283h4.94c1.893 0 3.577.282 5.39-.355a13.318 13.318 0 0 1 .989 1.469c.264.286.082.228.595.181.289-.96-.5-1.234-.711-2.035.28-.481.735-.747 1.032-1.171 1.833-2.627 1.336-4.89 1.761-5.3.63-.364 1.2.264 1.142-.7a1.189 1.189 0 0 0-.906-.267H5.41c-1.4 0-3.269.7-3.165-.792a10.014 10.014 0 0 0-.02-2.938l.085-3.055c-.024-1.7-.624-4.764 1.275-5.591a2.326 2.326 0 0 1 3.42 1.636c.068.6-.448.642-.821.954-1.013.845-.687 1.515-.762 2.8-.288.229-.391.239-.541.665.7.6 3.412-.906 4.647-1.293l1.936-.655c.77-.315.534-.45.465-.757-.5-.312-.608.257-1.11-.588l-.917-.846a3.325 3.325 0 0 0-1.967-.246C7.679 2.369 7.194-.039 4.587.004a3.211 3.211 0 0 0-3.094 3.135Z'
            fill={color}
            fillRule='evenodd'
        />
    </svg>
)

BathroomIcon.propTypes = {
    color: PropTypes.string,
}

export default BathroomIcon
