import { useState } from 'react'

const useOpenAndCloseHook = () => {
    const [open, setOpen] = useState(false)

    const handleChangeStatus = () => {
        setOpen(!open)
    }

    return [open, handleChangeStatus]
}

export default useOpenAndCloseHook
