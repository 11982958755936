import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Text } from '../Text'

const CardCheck = ({ title, icon, active, onClick }) => {

    return (
        <div className="cardCheck" onClick={onClick}>
            <div className="cardCheck_active">
                <div className={`cardCheck_active_item ${active ? 'cardCheck_select' : ''}`} />
            </div>
            <div className="cardCheck_icon">
                {icon}
            </div>
            <Text bold center>{title}</Text>
        </div>
    )
}

CardCheck.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.node,
    active: PropTypes.bool,
    onClick: PropTypes.func,
}

export default CardCheck