import * as React from 'react'
import PropTypes from 'prop-types'

const TypePropertieIcon = ({ color = '#69A831' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
        <g>
            <path fill='none' d='M0 0h20v20H0z' />
            <path
                d='m17.337 8.456-.64-.52-.793-.631-2.406-1.937-.938-.793-1.928-1.527a.973.973 0 0 0-1.237 0L7.488 4.575l-.99.793-2.4 1.937-.794.631-.631.512a.375.375 0 0 0-.145.273.367.367 0 0 0 .085.29.384.384 0 0 0 .264.136.367.367 0 0 0 .3-.085l.136-.1v6.245a2 2 0 0 0 .58 1.391 1.954 1.954 0 0 0 1.391.58h9.444a1.971 1.971 0 0 0 1.391-.572 2 2 0 0 0 .58-1.4V8.951l.145.111a.367.367 0 0 0 .469 0 .35.35 0 0 0 .145-.188.427.427 0 0 0 0-.23.384.384 0 0 0-.119-.188Zm-1.433 6.748a1.177 1.177 0 0 1-1.177 1.186H5.274a1.16 1.16 0 0 1-.853-.35 1.186 1.186 0 0 1-.35-.853V8.363L7.74 5.385l.973-.793 1.143-.921a.213.213 0 0 1 .128 0 .2.2 0 0 1 .119 0l1.143.921.973.793 3.66 2.977Z'
                fill={color}
            />
            <path
                d='M11.186 13.856H8.823a1.194 1.194 0 0 1-1.186-1.186v-2.363a1.194 1.194 0 0 1 1.186-1.16h2.363a1.169 1.169 0 0 1 .853.341 1.194 1.194 0 0 1 .35.853v2.363a1.194 1.194 0 0 1-1.182 1.186ZM8.823 9.915a.384.384 0 0 0-.282.119.444.444 0 0 0-.119.273v2.363a.4.4 0 0 0 .119.282.418.418 0 0 0 .282.111h2.363a.409.409 0 0 0 .273-.111.4.4 0 0 0 .119-.282v-2.363a.367.367 0 0 0-.119-.273.384.384 0 0 0-.273-.119Z'
                fill={color}
            />
        </g>
    </svg>
)

TypePropertieIcon.propTypes = {
    color: PropTypes.string,
}

export default TypePropertieIcon
