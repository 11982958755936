import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

// Components
import { Title } from './'

const TitleIcon = ({ icon, title, sizeIcon, iconTop, titleSize = 'md' }) => {
    const sizeIcons = {
        xl: 'xl',
        lg: 'lg',
        md: 'md',
        sm: 'sm',
        xs: 'xs',
    }

    const classTitleIcon = clsx(
        'titleIcon',
        `${sizeIcon ? `sizeIcon_${sizeIcons[sizeIcon]}` : ''}`,
        {
            'titleIconTop': iconTop,
        },
    )
    return (
        <div className={classTitleIcon}>
            {icon}
            <Title size={titleSize}>{title}</Title>
        </div>
    )
}

TitleIcon.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string,
    sizeIcon: PropTypes.string,
    iconTop: PropTypes.bool,
    titleSize: PropTypes.string,
}

export default TitleIcon
