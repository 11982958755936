import { Auth } from 'aws-amplify'

const BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`
const API_AWS = process.env.REACT_APP_API_AWS_HOST

export async function API_PUBLIC({ endpoint, method = 'GET', body }) {
    const config = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
    }
    if (body) {
        Object.assign(config, { body: JSON.stringify(body) })
    }

    const send = await fetch(BASE_URL + endpoint, config)

    return send.json()
}

export async function API_PRIVATE({ endpoint, method = 'GET', body }) {
    return Auth.currentAuthenticatedUser()
        .then(async userData => {
            const { idToken: { jwtToken } } = userData.signInUserSession

            const config = {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`,
                },
            }
        
            if (body) {
                Object.assign(config, { body: JSON.stringify(body) })
            }
            const request = await fetch(API_AWS + endpoint, config)

            if (request.ok) {
                return request.json()
            }

            throw new Error(request.statusText)
        }).catch(() => console.log('Not signed in'))
}
