import React from 'react'

// Icons
import { WhatsappShareIcon } from '../Svg'

// Utils
import { createUrlShare } from '../../utils/helpers'

const WhatsappShare = () => {
    return (
        <a
            href={`https://wa.me/?text=${createUrlShare()}`}
            target='_blank'
            rel='noreferrer'
            className='displayFlex'
        >
            <WhatsappShareIcon />
        </a>
    )
}

export default WhatsappShare
