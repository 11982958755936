import React from 'react'
import PropTypes from 'prop-types'

// Componenst
import { Modal } from '.'
import { Title } from '../Title'
import { Button } from '../Button'
import { CardCheck } from '../CardCheck'

// Icons
import { UserBuyIcon, UserSellIcon } from '../Svg'

// Const
import { USER_BUY, USER_SELL } from '../../const/typeUser'

const ModalTypeUser = ({ onClose, selectType, onSelectType, onConfirm }) => {
    return (
        <Modal header='Registrate Ahora!' onClose={onClose}>
            <div className='modalTypeUser'>

                <div className='modalTypeUser_options'>
                    <Title size='xs'>Selecciona tu interés</Title>
                    <div className='modalTypeUser_radios'>
                        <CardCheck
                            title='Encuentra una propiedad'
                            icon={<UserBuyIcon color='white' />}
                            active={selectType === USER_BUY}
                            onClick={() => onSelectType({ type: USER_BUY })}
                        />
                        <CardCheck
                            title='Vender mi propiedad'
                            icon={<UserSellIcon color='white' />}
                            active={selectType === USER_SELL}
                            onClick={() => onSelectType({ type: USER_SELL })}
                        />
                    </div>

                    <div className='modalTypeUser_btns'>
                        <Button alignSelfStart rounded onClick={onConfirm}>
                            Continuar
                        </Button>

                        <Button
                            textColor='gray'
                            transparent
                            icon
                            underline
                            onClick={onClose}
                            colorIcon='gray'
                            jContentStart
                        >
                            Cancelar
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

ModalTypeUser.propTypes = {
    onClose: PropTypes.func,
    selectType: PropTypes.string,
    onSelectType: PropTypes.func,
    onConfirm: PropTypes.func,
}

export default ModalTypeUser
