import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// Components
import { Title } from '../Title'
import {
    FeaturedPropertiesPrice,
    FeaturedPropertiesLocation,
    FeaturedPropertiesDetails,
} from '.'
import { SavePropertie } from '../SavePropertie'

import { useLogin } from '../../hooks'

const PropertiesCard = ({
    bed,
    bathroom,
    meters,
    name,
    location,
    price,
    image,
    id,
    hiddenDetails,
    goTo,
}) => {

    const { isLogged } = useLogin()

    return (
        <Link
            to={goTo}
            className='featuredProperties_card'
        >
            { isLogged &&
            <SavePropertie item={
                {
                    image,
                    id,
                    details: { bed, bathroom, meters },
                    location,
                    price,
                    name,
                }
            }/>
            }
            <img src={image} alt='Images' />

            <div className='featuredProperties_card_content'>
                <div className='featuredProperties_card_content_details'>
                    {
                        !hiddenDetails && (
                            <FeaturedPropertiesDetails
                                bed={bed}
                                bathroom={bathroom}
                                meters={meters}
                            />
                        )
                    }

                    <Title size='sm'>{name}</Title>

                    <FeaturedPropertiesLocation location={location} />
                </div>

                <FeaturedPropertiesPrice price={price} />
            </div>
        </Link>
    )
}

PropertiesCard.propTypes = {
    save: PropTypes.bool,
    bed: PropTypes.number,
    bathroom: PropTypes.number,
    meters: PropTypes.number,
    name: PropTypes.string,
    location: PropTypes.string,
    price: PropTypes.string,
    image: PropTypes.string,
    hiddenDetails: PropTypes.bool,
    goTo: PropTypes.string,
    id: PropTypes.number,
}

export default PropertiesCard
