import * as React from 'react'
import PropTypes from 'prop-types'

const MenuIcon = ({ color = '#69A831' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={26} height={22}>
        <g transform='translate(4280 -17660)' fill={color}>
            <rect width={26} height={2} rx={1} transform='translate(-4280 17660)' />
            <rect width={26} height={2} rx={1} transform='translate(-4280 17680)' />
            <rect width={16} height={2} rx={1} transform='translate(-4270 17670)' />
        </g>
    </svg>
)

MenuIcon.propTypes = {
    color: PropTypes.string,
}

export default MenuIcon
