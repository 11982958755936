import React from 'react'
import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'

const LoadingFavorites = ({ count = 1 }) => (
    <>
        {new Array(count).fill('').map((item, index) => (
            <ContentLoader key={index} viewBox="0 0 800 130">
                <rect x="0" y="5" rx="5" ry="5" width="200" height="120" />
                <rect x="220" y="5" rx="5" ry="5" width="420" height="25" />
                <rect x="220" y="51" rx="5" ry="5" width="315" height="25" />
                <rect x="220" y="100" rx="5" ry="5" width="233" height="25" />
            </ContentLoader>
        ))}
    </>
)

LoadingFavorites.propTypes = {
    count: PropTypes.number,
}

export default LoadingFavorites
