import * as React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

// Context
import { UserContextProvider } from './Context/UserContext'

// Components
import { Layout } from './components/Layout'

// Pages
import { Register } from './pages/Auth/Register'
import { Home } from './pages/Home'
import { Properties } from './pages/Properties'
import { AboutUs } from './pages/AboutUs'
import { Blog } from './pages/Blog'
import { Property } from './pages/Property'
import BlogPost from './pages/BlogPost/BlogPost'
import {
    //DashboardPage,
    DashboardAccount,
    DashboardLayout,
    DashboardProperties,
} from './pages/Dashboard'
import DashboardFavorite from './pages/Dashboard/DashboardFavorite'
import { PlacesOfInterest } from './pages/PlacesOfInterest'
import { Lotes } from './pages/Lotes'
import { Lote } from './pages/Lote'
import { Parcellings } from './pages/Parcellings'
import { Parcelling } from './pages/Parcelling'
import { DesignAndConstruction } from './pages/DesignAndConstruction'
import { PrivacyPolicy } from './pages/Policy/PrivacyPolicy'
import { TermsAndConditions } from './pages/Policy/TermsAndConditions'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Helpers
import {
    HOME_ROUTER,
    PROPERTIES_ROUTER,
    SELLS_ROUTER,
    ABOUTUS_ROUTER,
    BLOG_ROUTER,
    REGISTER_ROUTER,
    PROPERTY_ROUTER,
    BLOG_POST_ROUTER,
    DASHBOARD_ROUTER,
    DASHBOARD_PROPERTIES_ROUTER,
    DASHBOARD_FAVORITE_ROUTER,
    PLACES_OF_INTEREST_ROUTER,
    LOTES_ROUTER,
    LOTE_ROUTER,
    PARCELLINGS_ROUTER,
    PARCELLING_ROUTER,
    DESING_AND_CONSTRUCTION_ROUTER,
    PRIVACY_POLICY_ROUTER,
    TERMS_AND_CONDITIONS_ROUTER,
} from './helpers/constants/routes'

const App = () => {
    return <>
        <UserContextProvider>
            <BrowserRouter>
                <Routes>
                    <Route path={HOME_ROUTER.path} element={<Layout />}>
                        <Route path={HOME_ROUTER.path} element={<Home />} />
                        <Route path={ABOUTUS_ROUTER.path} element={<AboutUs />} />

                        {/* Blog */}
                        <Route path={BLOG_ROUTER.path} element={<Blog />} />
                        <Route path={`${BLOG_POST_ROUTER.path}/:id`} element={<BlogPost />} />

                        {/* Sitio de Interes */}
                        <Route
                            path={`${PLACES_OF_INTEREST_ROUTER.path}/:id`}
                            element={<PlacesOfInterest />}
                        />

                        {/* Propiedad */}
                        <Route path={PROPERTIES_ROUTER.path} element={<Properties />} />
                        <Route path={`${PROPERTY_ROUTER.path}/:id`} element={<Property />} />

                        {/* Lotes */}
                        <Route path={LOTES_ROUTER.path} element={<Lotes />} />
                        <Route path={`${LOTE_ROUTER.path}/:id`} element={<Lote />} />

                        {/* Parcelacion */}
                        <Route path={PARCELLINGS_ROUTER.path} element={<Parcellings />} />
                        <Route path={`${PARCELLING_ROUTER.path}/:id`} element={<Parcelling />} />

                        {/* Design and Construction */}
                        <Route path={DESING_AND_CONSTRUCTION_ROUTER.path} element={<DesignAndConstruction />} />

                        {/* Policy */}
                        <Route
                            path={PRIVACY_POLICY_ROUTER.path}
                            element={<PrivacyPolicy />}
                        />
                        <Route
                            path={TERMS_AND_CONDITIONS_ROUTER.path}
                            element={<TermsAndConditions />}
                        />

                        {/* Dashboard */}
                        <Route path={DASHBOARD_ROUTER.path} element={<DashboardLayout />}>
                            <Route path={DASHBOARD_ROUTER.path} element={<DashboardAccount />} />
                            <Route
                                path={DASHBOARD_PROPERTIES_ROUTER.path}
                                element={<DashboardProperties />}
                            />
                            <Route
                                path={DASHBOARD_FAVORITE_ROUTER.path}
                                element={<DashboardFavorite />}
                            />
                        </Route>
                    </Route>

                    <Route exact path={REGISTER_ROUTER.path} element={<Register />} />
                    <Route path={SELLS_ROUTER.path} element={<Register />} />

                    <Route path='*' element={<Navigate to={HOME_ROUTER.path} />} />
                </Routes>
            </BrowserRouter>
        </UserContextProvider>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            theme="light"
        />
    </>
}

export default App
