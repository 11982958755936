import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

// Components
import { BlogHero } from '../Blog/components/BlogHero'
import { Text } from '../../components/Text'
import { FeaturedProperties } from '../../components/FeaturedProperties'
import { LoadingPost } from '../../components/Loading'
import { RenderMarkDown } from '../../components/RenderMarkDown'
import { FacebookShare, WhatsAppShare } from '../../components/SocialShare'

// Fields
import {
    FIELDS_PLACES_OF_INTEREST,
    FIELD_KEY_CONTENT,
    FIELD_KEY_CREATED,
    FIELD_KEY_DESCRIPTION,
    FIELD_KEY_IMG_PRIMARY,
    FIELD_KEY_PROPERTIES,
    FIELD_KEY_NAME,
    FIELD_KEY_IMG_HEADER,
} from '../../utils/fields'

// Routes
import { HOME_ROUTER } from '../../helpers/constants/routes'

// Hooks
import { usePlacesOfInterest } from '../../hooks'

const PlacesOfInterest = () => {
    const { placeOfInterest, loadingPLaces, getPlaceOfInt } =
        usePlacesOfInterest()
    const { id } = useParams()

    useEffect(() => {
        (async () => {
            try {
                await getPlaceOfInt({ id })
            } catch (e) {
                console.error(e)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    if (loadingPLaces) return <LoadingPost />

    return (
        <div className='blogPost'>
            <BlogHero
                image={
                    placeOfInterest.attributes[
                        FIELDS_PLACES_OF_INTEREST[FIELD_KEY_IMG_HEADER]
                    ].data.attributes.url
                }
                title={
                    placeOfInterest.attributes[FIELDS_PLACES_OF_INTEREST[FIELD_KEY_NAME]]
                }
                description={
                    placeOfInterest.attributes[
                        FIELDS_PLACES_OF_INTEREST[FIELD_KEY_DESCRIPTION]
                    ]
                }
                date={
                    placeOfInterest.attributes[
                        FIELDS_PLACES_OF_INTEREST[FIELD_KEY_CREATED]
                    ]
                }
                goBack={HOME_ROUTER.path}
            />

            <div className='blogPost_container'>
                <div className='blogPost_content'>
                    <img
                        src={
                            placeOfInterest.attributes[
                                FIELDS_PLACES_OF_INTEREST[FIELD_KEY_IMG_PRIMARY]
                            ].data.attributes.url
                        }
                        alt='Post Header'
                    />

                    <RenderMarkDown>
                        {
                            placeOfInterest.attributes[
                                FIELDS_PLACES_OF_INTEREST[FIELD_KEY_CONTENT]
                            ]
                        }
                    </RenderMarkDown>

                    <div className='blogPost_share'>
                        <Text bold>Comparte</Text>

                        <div className='blogPost_share_social'>
                            <FacebookShare />
                            <WhatsAppShare />
                        </div>
                    </div>
                </div>
            </div>

            {placeOfInterest.attributes[
                FIELDS_PLACES_OF_INTEREST[FIELD_KEY_PROPERTIES]
            ].data.length > 0 && (
                <div className='container'>
                    <div className='content'>
                        <FeaturedProperties
                            title='Propiedades destacadas en esta zona'
                            description='Sé el primero en conocer estas propiedades antes que muchas personas.'
                            listProperty={
                                placeOfInterest.attributes[
                                    FIELDS_PLACES_OF_INTEREST[FIELD_KEY_PROPERTIES]
                                ].data
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default PlacesOfInterest
