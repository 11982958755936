import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Components
import { Text } from '../../components/Text'
import { LoadingPost } from '../../components/Loading'
import { RenderMarkDown } from '../../components/RenderMarkDown'
import { Maps } from '../../components/Maps'
import { PropertyGalery } from '../Property/Components/PropertyGalery'
import { SliderGalery } from '../Property/Components/SliderGalery'
import { TitleIcon } from '../../components/Title'
import { ShowFeatured } from '../../components/ShowFeatured'
import { PropertyHeader } from '../Property/Components/PropertyHeader'
import { PlacesOfInterest } from '../Property/Components/PlacesOfInterest'
import { PropertyContact } from '../Property/Components/PropertyContact'

// Fields
import {
    FIELD_LOTES,
} from '../../utils/fields'

// Icons
import { InfoIcon } from '../../components/Svg'

// Hooks
import { useLotes, useOpenAndCloseHook } from '../../hooks'

// Helpers
import { scrollTop } from '../../helpers/utils'

// Utils
import { TRANS_FIELDS_LOTES } from '../../utils/transFields'
import { IFNORER_BASIC_INFO_LOTE } from '../../utils/ignorerItems'
import { createUrlShare } from '../../utils/helpers'

const SHOW_MUNICIPALITY = [FIELD_LOTES.location]

const SHOW_AREA = [FIELD_LOTES.lot_area]

const SHOW_DIFERENT = [...SHOW_MUNICIPALITY, ...SHOW_AREA]

const Lote = () => {
    const { loteInfo, loadingLotes, getLote } = useLotes()
    const [openGalery, setOpenGalery] = useOpenAndCloseHook()
    const { id } = useParams()

    useEffect(() => {
        (async () => {
            try {
                scrollTop()
                await getLote({ id })
            } catch (e) {
                console.error(e)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const showDetailDiferent = ({ field }) => {
        if (SHOW_MUNICIPALITY.includes(field))
            return loteInfo.attributes[field].data.attributes.Nombre

        if (SHOW_AREA.includes(field))
            return (
                <span>
                    {loteInfo.attributes[field]}m<sup>2</sup>
                </span>
            )

        return ''
    }

    if (loadingLotes) return <LoadingPost />


    const showItems = Object.keys(loteInfo.attributes).filter(
        (field) =>
            !IFNORER_BASIC_INFO_LOTE.includes(field) &&
            !!loteInfo.attributes[field],
    )

    return (
        <> 
            <Helmet>
                <meta charSet='utf-8' />
                <title>TierrasGP | {loteInfo.attributes[FIELD_LOTES.name]}</title>
                <link rel='canonical' href={createUrlShare({ decode: false })} />

                <meta name="title" content={`TierrasGP | ${loteInfo.attributes[FIELD_LOTES.name]}`} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={createUrlShare({ decode: false })} />
                <meta property="og:title" content={`TierrasGP | ${loteInfo.attributes[FIELD_LOTES.name]}`} />
                <meta property="og:image" content={loteInfo.attributes[FIELD_LOTES.imgPrimary].data.attributes.url} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={createUrlShare({ decode: false })} />
                <meta property="twitter:title" content={`TierrasGP | ${loteInfo.attributes[FIELD_LOTES.name]}`} />
                <meta property="twitter:image" content={loteInfo.attributes[FIELD_LOTES.imgPrimary].data.attributes.url}></meta>
            </Helmet>
            <div className='container'>
                <div className='content property'>
                    <PropertyHeader
                        name={loteInfo.attributes[FIELD_LOTES.name]}
                        location={`${loteInfo.attributes[FIELD_LOTES.location].data.attributes.Nombre} ${loteInfo.attributes[FIELD_LOTES.sector] ? `- ${loteInfo.attributes[FIELD_LOTES.sector]}` : ''}`}
                        price={loteInfo.attributes[FIELD_LOTES.price]}
                        code={loteInfo.attributes[FIELD_LOTES.code]}
                    />
                    <PropertyGalery
                        imgPrimary={
                            loteInfo.attributes[FIELD_LOTES.imgPrimary]
                        }
                        galery={loteInfo.attributes[FIELD_LOTES.galery]}
                        onOpenGalery={setOpenGalery}
                    />

                    <div className='property_info'>
                        <div className='property_item'>
                            <TitleIcon
                                sizeIcon='xl'
                                icon={<InfoIcon />}
                                title='Información basica'
                            />
                            <RenderMarkDown>
                                {
                                    loteInfo.attributes[
                                        FIELD_LOTES.basicInformation
                                    ]
                                }
                            </RenderMarkDown>

                            <ShowFeatured 
                                data={loteInfo.attributes}
                                showDetailDiferent={showDetailDiferent}
                                showDiferent={SHOW_DIFERENT}
                                showItems={showItems}
                                transField={TRANS_FIELDS_LOTES}
                            />

                            <div className='property_description bg_grey'>
                                <Text bold>Descripción de la propiedad</Text>
                                <RenderMarkDown>
                                    {loteInfo.attributes[FIELD_LOTES.description]}
                                </RenderMarkDown>
                            </div>

                            {loteInfo?.attributes[
                                FIELD_LOTES.embeddedVideo
                            ] && (
                                <div
                                    className='property_video'
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            loteInfo?.attributes[
                                                FIELD_LOTES.embeddedVideo
                                            ],
                                    }}
                                ></div>
                            )}

                            {loteInfo.attributes[FIELD_LOTES.lat] &&
                                    loteInfo.attributes[FIELD_LOTES.lng] && (
                                <div className='property_map'>
                                    <Maps
                                        lat={loteInfo.attributes[FIELD_LOTES.lat]}
                                        lng={loteInfo.attributes[FIELD_LOTES.lng]}
                                    />
                                </div>
                            )}
                        </div>
                        <div className='property_item'>
                            <PropertyContact />
                        </div>
                    </div>
                    

                    {loteInfo?.attributes[FIELD_LOTES.placesOfInterest]?.data
                        .length > 0 && (
                        <PlacesOfInterest
                            listPlaces={
                                loteInfo.attributes[FIELD_LOTES.placesOfInterest].data
                            }
                        />
                    )}
                </div>
            </div>

            {openGalery && (
                <SliderGalery
                    galery={[
                        loteInfo.attributes[FIELD_LOTES.imgPrimary].data,
                        ...loteInfo.attributes[FIELD_LOTES.galery].data,
                    ]}
                    close={setOpenGalery}
                />
            )}
        </>
    )
}

export default Lote
