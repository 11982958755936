import * as React from 'react'
import PropTypes from 'prop-types'

const UserSellIcon = ({ color = '#062828' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox='0 0 30.76 32.439'
    >
        <g data-name="Grupo 4955" fill={color}>
            <path
                d="M19.327 0H3.042A2.813 2.813 0 0 0 0 3.046v26.348a2.814 2.814 0 0 0 3.044 3.044h16.285a2.815 2.815 0 0 0 3.043-3.046V3.043A2.814 2.814 0 0 0 19.327 0Zm1.926 29.431a1.7 1.7 0 0 1-1.871 1.888H2.988a1.694 1.694 0 0 1-1.87-1.855V2.971a1.688 1.688 0 0 1 1.836-1.852h16.462a1.689 1.689 0 0 1 1.835 1.854v26.459Z"
            />
            <path
                d="M30.759 19.083a.56.56 0 0 0-.558-.625.566.566 0 0 0-.559.625v.245c0 1.678-.02 3.356.008 5.033a1.984 1.984 0 0 1-1.127 1.895v-.431c0-4.358-.029-8.715.019-13.073a5.541 5.541 0 0 0-.775-3.215 5.482 5.482 0 0 1-.922-3.8.583.583 0 0 0 0-.07c-.011-.387-.22-.629-.549-.634s-.564.242-.568.65q-.007.873 0 1.748a1.031 1.031 0 0 1-.118.5c-.483.953-.952 1.912-1.434 2.866a1.13 1.13 0 0 0-.13.533v20.413c0 .5.2.693.7.694h3.076c.5 0 .7-.2.7-.7v-2.761a3.17 3.17 0 0 1 .057-1.271c.163-.337.621-.534.959-.783a2.839 2.839 0 0 0 1.184-1.9 2.053 2.053 0 0 0 .033-.312v-5.627Zm-4.474-10 .77 1.531h-1.531Zm.867 22.242a27.471 27.471 0 0 0-1.746 0c-.211.006-.245-.073-.241-.259.011-.582.014-1.165 0-1.747-.006-.211.073-.245.259-.241.582.011 1.165.014 1.747 0 .211-.006.25.073.241.259-.014.278 0 .559 0 .838s-.011.583 0 .874c.01.2-.024.286-.258.278Zm-.062-3.355a24.677 24.677 0 0 0-1.677 0c-.2 0-.249-.055-.249-.251q.008-7.863 0-15.728c0-.2.052-.254.249-.249.559.013 1.119.021 1.677 0 .264-.011.322.064.32.323-.01 2.609-.007 5.219-.007 7.829s0 5.173.007 7.759c0 .262-.061.332-.322.321Z"
            />
            <path
                d="M3.62 21.723a.792.792 0 0 0 .472.09h14.214c.448 0 .7-.2.707-.546s-.252-.569-.716-.572H4.188a2.276 2.276 0 0 0-.348.008.555.555 0 0 0-.22 1.02Z"
            />
            <path
                d="M18.469 24.047a.557.557 0 0 0 .013-1.113c-.092-.008-.186 0-.279 0H4.162a1.849 1.849 0 0 0-.314.007.556.556 0 0 0-.014 1.1 1.593 1.593 0 0 0 .314.009H18.468Z"
            />
            <path
                d="M18.409 28.52c-.384-.007-.769 0-1.153 0a.423.423 0 0 1-.478-.466c-.012-.527-.351-.771-.836-.582-.887.345-1.769.7-2.652 1.056-.021.008-.042.02-.063.03a.56.56 0 0 0-.335.711.551.551 0 0 0 .742.321c.67-.254 1.334-.524 2.007-.79.064.739.174.839.911.839.618 0 1.235.006 1.852 0a.559.559 0 1 0 .007-1.114Z"
            />
            <path
                d="M15.654 26.286h2.687c.416 0 .664-.206.674-.544s-.248-.573-.678-.574h-5.339c-.44 0-.692.209-.69.561s.253.556.7.557c.879.006 1.762 0 2.646 0Z"
            />
            <path
                d="M4.01 19.573h4.849c.405 0 .655-.226.65-.567s-.253-.548-.666-.549H4.029c-.414 0-.661.209-.667.548s.243.567.648.567Z"
            />
            <path
                d="M5.31 8.937c.266 0 .288.1.287.322a698.667 698.667 0 0 0 0 5.417 1.415 1.415 0 0 0 1.539 1.543h8.038a1.437 1.437 0 0 0 1.601-1.614V9.223c0-.119-.075-.268.175-.282a.874.874 0 0 0 .908-.665.908.908 0 0 0-.425-1.019q-2.806-2.106-5.616-4.208a.922.922 0 0 0-1.273 0Q9.62 3.737 8.7 4.43L4.872 7.297a.878.878 0 0 0-.369.979.833.833 0 0 0 .8.661Zm6.994 4.771v1.153c0 .161-.01.251-.218.246a39.309 39.309 0 0 0-1.817 0c-.176 0-.21-.059-.208-.22q.011-1.188 0-2.376c0-.176.059-.211.22-.208.605.008 1.212.01 1.817 0 .176 0 .213.059.208.22-.01.4 0 .792 0 1.187Zm3.36.964c0 .317-.124.44-.437.433-.5-.013-1-.017-1.5 0-.238.009-.315-.046-.309-.3.018-.8.009-1.606.006-2.41a1.127 1.127 0 0 0-1.225-1.209h-2.026a1.127 1.127 0 0 0-1.224 1.211c0 .815-.01 1.63.006 2.444 0 .223-.062.271-.272.264a31.646 31.646 0 0 0-1.571 0c-.278 0-.4-.11-.4-.394 0-1.84.008-3.678 0-5.518 0-.25.11-.246.29-.245h8.38c.219 0 .292.043.291.28q-.017 2.724 0 5.448ZM11.006 4.106c.108-.081.181-.143.325-.034 1.641 1.238 3.289 2.468 4.983 3.737H6.058c1.688-1.262 3.317-2.481 4.948-3.703Z"
            />
        </g>
    </svg>
)

UserSellIcon.propTypes = {
    color: PropTypes.string,
}

export default UserSellIcon