import { useContext } from 'react'
import Context from '../Context/UserContext'

export default function useLogin() {
    const { user: userLogged, setUser, pending: loadingUser } = useContext(Context)

    const setLogin = async (user) => {
        //window.sessionStorage.setItem('jwt', user.token)
        //window.sessionStorage.setItem('user', JSON.stringify(user.user))
        setUser(user)
    }

    return {
        loadingUser,
        isLogged: Boolean(userLogged),
        setLogin,
    }
}
