import * as React from 'react'
import PropTypes from 'prop-types'

const BedIcon = ({ color }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.046 21.362'>
        <path
            d='M.871 16.861c0-.425.1-.574.5-.574H20.61c.555 0 .687.245.687.8 0 2.669.345 3.52-.8 3.52-2.645 0-.986-.938-1.658-2.312-1.09-.131-2.8-.027-3.969-.027-2.358 0-10.678-.175-11.833.142a5.932 5.932 0 0 1-.156 2.107 1.045 1.045 0 0 1-.5.091c-1.269 0-1.509.143-1.509-.594v-3.152Zm.609-1.294c-.746 0-.262-.828-.15-1.212l.962-2.985c.629-1.592.168-2.118 1.572-2.361a15.042 15.042 0 0 1 1.783-.189c1.126-.046 2.453-.273 3.484-.273h4.726c.976 0 2.314.21 3.374.285 1.953.138 1.944.315 2.225 1.428.125.5.265.82.437 1.356.316.985 1.54 3.95.715 3.95H1.48Zm10.009-8.836a1.523 1.523 0 0 1 1.124-1.241h3.386c.983 0 1.344 1.142 1.133 2.127l-.186.3c-.021.006-.059.028-.086.034l-4.471-.117c-.943-.009-.9-.135-.9-1.1Zm-6.569.113c0-1.816 2.143-1.354 4.437-1.354 1.133 0 1.38 1.457 1.057 2.3-.693.1-2.34.1-3.086.129-2.41.094-2.408.741-2.408-1.072ZM3.12 1.89A.987.987 0 0 1 4.181.809h13.728c.482 0 1.048.393 1.048.859 0 1.065.12 5.865-.083 6.517l-.906-.087c-.225-.815.111-1.687-.3-2.363a1.855 1.855 0 0 0-1.669-.966c-2.707 0-3.418-.337-4.951.708-.606-.226-.57-.708-1.691-.708-2.421 0-4.234-.428-5.013 1.115-.445.866-.056 1.414-.316 2.266-.419.151-.908.251-.908-.405V1.89ZM.06 21.099c.84.4 1.625.227 2.994.227 1.776 0-.113-2.339 1.35-2.339h13.5c.4 0 .308.084.343.448.019.2-.018.694-.021.922-.019 1.248.205.969 3.167.969.843 0 .623-.775.62-1.539s0-1.5 0-2.251a8.083 8.083 0 0 0-.686-3.863l-1.61-5.254c-.121-1.537.169-6.741-.16-7.395A1.96 1.96 0 0 0 17.909 0H4.173a1.772 1.772 0 0 0-1.765 1.356 56.845 56.845 0 0 0-.1 6.164c.007 2.161-1.366 5.286-2 7.5-.49 1.706-.248 4.1-.248 6.078Z'
            fill={color}
            fillRule='evenodd'
        />
    </svg>
)

BedIcon.propTypes = {
    color: PropTypes.string,
}

export default BedIcon
