import { useState, useEffect, useCallback } from 'react'
import { API_PRIVATE } from '../service/api'

const useFetch = ({ endpoint }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const fetchData = useCallback(() => {
        try {
            setLoading(true)
            API_PRIVATE({ endpoint })
                .then(response => setData(response))
                .catch(error => setError(error))
                .finally(() => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 500)
                })
        } catch (error) {
            setError(error)
            setLoading(false)
        }
    }, [endpoint])

    useEffect(() => {
        fetchData()
    }, [endpoint])

    return { data, loading, error }
}

export default useFetch