import Amplify, { Auth } from 'aws-amplify'

// Helpers
import { getRandomString } from '../utils/helpers'

let cognitoUser // Track authentication flow state in this object

Amplify.configure({
    Auth: {
        region: 'us-east-1',
        userPoolId: process.env.REACT_APP_AWS_COGNITO_POOLID,
        userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENTID,
        oauth: {
            redirectSignIn: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_IN,
            redirectSignOut: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_OUT,
            domain: process.env.REACT_APP_COGNITO_DOMAIN,
            scope: ['openid', 'email', 'profile', 'phone'],
            responseType: 'code',
        },
    },
})

export async function signUp({ username, deliveryMethod }) {
    const params = {
        username,
        password: getRandomString({ bytes: 30 }),
        attributes: {
            'custom:custom': JSON.stringify({
                deliveryMethod,
            }),
        },
    }
    const res = await Auth.signUp(params)
    return res
}

export async function signInGoogle() {
    const res = await Auth.federatedSignIn({ provider: 'Google' })
    return res
}

export async function signInFacebook() {
    const res = await Auth.federatedSignIn({ provider: 'Facebook' })
    return res
}

export async function signIn({ username }) {
    const user = await Auth.signIn(username)
    cognitoUser = user
    return user
}

export async function answerCustomChallenge(answer) {
    const result = await Auth.sendCustomChallengeAnswer(cognitoUser, answer)
    try {
        cognitoUser = result
        await Auth.currentSession()
    } catch (e) {
        return 'failed'
    }
}

export const signOut = () => {
    return Auth.signOut()
}
