import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Button } from './'
import { Text } from '../Text'

const ButtonMoreAndLess = ({ number, onClick }) => {
    const handleChange = ({ value }) => {
        onClick && onClick({ value: value })
    }

    return (
        <div className='buttonMoreAndLess'>
            {Number(number) > 0 && (
                <Button
                    transparent
                    textColor='primary'
                    className='buttonMoreAndLess_btn'
                    onClick={() => handleChange({ value: number - 1 })}
                >
                    -
                </Button>
            )}
            <Text>{number}</Text>
            <Button
                transparent
                textColor='primary'
                className='buttonMoreAndLess_btn'
                onClick={() => handleChange({ value: number + 1 })}
            >
                +
            </Button>
        </div>
    )
}

ButtonMoreAndLess.propTypes = { 
    number: PropTypes.number, 
    onClick: PropTypes.func, 
}

export default ButtonMoreAndLess
