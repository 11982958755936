import { useState, useCallback } from 'react'
import { API_PUBLIC } from '../service/api'

// Hooks
import { useDebounce, usePagination } from './'

// Utils
import {
    FIELD_PARCELLING,
    FIELD_ORDER_BY,
    FIELD_KEY_LOCATION,
    FIELD_KEY_PRICE,
} from '../utils/fields'

// Const
import { MIN_PRICE } from '../const/inputRange'
import { ORDER_BY_PUBLISHED_AT } from '../const/queryEndpoint'
import { LIST_ORDER_BY } from '../const/selectValues'

const POPULATE = 'populate'
const ENDPOINT = '/parcelacions'
const POPULATE_MUNICIPALITY = `${POPULATE}[${FIELD_PARCELLING.location}][${POPULATE}]`
const POPULATE_IMG_PRIMARY = `${POPULATE}[${FIELD_PARCELLING.imgPrimary}][${POPULATE}]`
const POPULATE_IMG_HEADER = `${POPULATE}[${FIELD_PARCELLING.imgHeader}][${POPULATE}]`
const POPULATE_LOTES = `${POPULATE}[${FIELD_PARCELLING.lotes}][${POPULATE}]=%2A&${POPULATE}[${FIELD_PARCELLING.lotes}][${FIELD_PARCELLING.imgPrimary}][${POPULATE}]`
const POPULATE_GALERY = `${POPULATE}[${FIELD_PARCELLING.galery}][${POPULATE}]`
const ITEM_FOR_PAGE = 10

const useParcellings = () => {
    const [listParcellings, setListParcellings] = useState({})
    const [parcelingInfo, setParcelingInfo] = useState({})
    const [errorParcellings, setErrorParcellings] = useState('')
    const [loadingParcellings, setLoadingParcellings] = useState(true)
    const [dataFilterParcellings, setDataFilterParcellings] = useState({
        [FIELD_ORDER_BY]: LIST_ORDER_BY[1],
    })
    const { totalPage, page, handleChangePage, handleConfigPage } =
        usePagination()

    // buscar una parcelacion por id
    const getParceling = async ({ id }) => {
        try {
            setLoadingParcellings(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}/${id}?${POPULATE_IMG_PRIMARY}&${POPULATE_GALERY}&${POPULATE_MUNICIPALITY}&${POPULATE_IMG_HEADER}&${POPULATE_LOTES}`,
            })
            setParcelingInfo(res.data)
        } catch (error) {
            setErrorParcellings(error)
        } finally {
            setLoadingParcellings(false)
        }
    }

    // Buscar todas las parcelaciones
    const getParcellings = async ({ filter = '' } = {}) => {
        try {
            setLoadingParcellings(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}?${filter || `${createUriPagination({ page: 1 })}&${ORDER_BY_PUBLISHED_AT}`
                }&${POPULATE_IMG_PRIMARY}&${POPULATE_MUNICIPALITY}`,
            })

            if (totalPage !== res.meta.pagination.total) {
                handleConfigPage({
                    totalItem: res.meta.pagination.total,
                    itemForPage: ITEM_FOR_PAGE,
                })
            }

            setListParcellings(res)
            return res
        } catch (error) {
            setErrorParcellings(error)
        } finally {
            setLoadingParcellings(false)
        }
    }

    // Filtrar la lista
    const createUriPagination = ({ page }) => {
        return `pagination[pageSize]=${ITEM_FOR_PAGE}&pagination[page]=${page}`
    }

    // Filter between
    const createUriFilterBetween = ({ item, datafilter }) => {
        return Object.keys(datafilter[item])
            .map(
                (itemRange) =>
                    `filters[${FIELD_PARCELLING[item]}][$between]=${datafilter[item][itemRange] || 0
                    }`,
            )
            .join('&')
    }

    const createUriFilter = ({ datafilter, page }) => {
        return [
            ...Object.keys(datafilter).map((item) => {
                // Filters key $contains
                if (FIELD_KEY_LOCATION === item) {
                    if (Array.isArray(datafilter[item])) {
                        return datafilter[item]
                            .map(
                                (filter) =>
                                    `filters[${FIELD_PARCELLING[item]}][Nombre][$eq]=${filter.value}`,
                            )
                            .join('&')
                    }

                    return `filters[${FIELD_PARCELLING[item]}][Nombre][$contains]=${datafilter[item]}`
                }

                if (FIELD_PARCELLING.code === item) return `filters[${FIELD_PARCELLING.code}][$contains]=${datafilter[item]}`

                // Filter key $between
                if (
                    (datafilter[item]?.max > 0 && datafilter[item]?.min > 0) &&
                    FIELD_KEY_PRICE === item
                ) {
                    // PRICE
                    if (
                        datafilter[item]?.max - 1 > MIN_PRICE
                    ) {
                        return createUriFilterBetween({ datafilter, item })
                    }

                    if (
                        datafilter[item]?.max - 1 !== datafilter[item]?.min
                    ) {
                        return createUriFilterBetween({ datafilter, item })
                    }
                }

                // Order by
                if (
                    item === FIELD_ORDER_BY &&
                    datafilter[FIELD_ORDER_BY].value !== ''
                ) {
                    return `sort=${FIELD_PARCELLING.price}%3A${datafilter[FIELD_ORDER_BY].value}`
                }

                return ORDER_BY_PUBLISHED_AT
            }),
            createUriPagination({ page }),
        ]
            .filter(Boolean)
            .join('&')
    }

    const filterParcellings = ({ field, value }) => {
        const filter = {
            ...dataFilterParcellings,
            [field]: value,
        }

        const urlFilter = createUriFilter({ datafilter: filter, page: 1 })

        handleChangePage({ page: 1 })
        setDataFilterParcellings(filter)

        filterDebounce({ urlFilter })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filterDebounce = useCallback(
        useDebounce(({ urlFilter }) => {
            getParcellings({ filter: urlFilter })
        }, 1000),
        [],
    )

    const handleChangePageParceling = async ({ page }) => {
        handleChangePage && handleChangePage({ page })
        filterDebounce({
            urlFilter: createUriFilter({
                datafilter: dataFilterParcellings,
                page,
            }),
        })
    }

    const defaultFilter = async ({ data }) => {
        setDataFilterParcellings(data)
        return createUriFilter({ datafilter: data, page: 1 })
    }

    return {
        parcelingInfo,
        listParcellings,
        errorParcellings,
        loadingParcellings,
        dataFilterParcellings,
        totalPage,
        page,
        getParceling,
        getParcellings,
        filterParcellings,
        handleChangePageParceling,
        defaultFilter,
    }
}

export default useParcellings