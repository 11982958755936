import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

// Cognito
import {
    signIn,
    signInFacebook,
    signInGoogle,
    answerCustomChallenge,
} from '../../service/AwsCognitoConector'

// Componenst
import { Modal } from '.'
import { Title } from '../Title'
import { Button, ButtonGoogle, ButtonFacebook } from '..//Button'
import { Input } from '../Input'
import PhoneInput from '../PhoneInput/PhoneInput'
import ConfirmCode from '../ConfirmCode/ConfirmCode'

// Fields
import { FIELD_KEY_EMAIL } from '../../utils/fields'

// Icons
import { ArrowRightIcon } from '../Svg'

// Helpers
import { validateEmail } from '../../utils/helpers'

import { DASHBOARD_ROUTER } from '../../helpers/constants/routes'

const MODAL_PHONE = 1
const MODAL_EMAIL = 2

const Login = ({ close }) => {
    const [step, setStep] = useState(MODAL_PHONE)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [code, setCode] = useState('')
    const [phone, setPhone] = useState('')
    const [modalCofirm, setModalConfirm] = useState(false)
    const [pending, setPending] = useState(false)
    const [excededAttempts, setExcededAttempts] = useState(false)

    const navigate = useNavigate()

    const handleChangeStep = () => {
        setEmailError('')
        setStep(step === MODAL_PHONE ? MODAL_EMAIL : MODAL_PHONE)
    }

    const handleLogin = async () => {
        try {
            setEmailError('')
            if (step === MODAL_EMAIL) {
                if (!validateEmail({ email }))
                    return setEmailError('El email no es correcto')
            }

            if (step === MODAL_PHONE) {
                if (!phone) return setEmailError('Ingresa un número de teléfono')
            }

            setPending(true)
            const user = await signIn({ username: step === MODAL_PHONE ? phone : email })

            if (user.challengeName === 'CUSTOM_CHALLENGE') {
                setModalConfirm(!modalCofirm)
            }

        } catch (error) {
            setEmailError('El usuario no existe')
        } finally {
            setPending(false)
        }
    }

    const handleConfirmOTP = async () => {
        setEmailError('')
        if (excededAttempts) {
            close()
            return
        }

        try {
            setPending(true)
            const result = await answerCustomChallenge(code)
            if (result === 'failed') {
                setEmailError('El código ingresado es incorrecto')
                return
            }
            close()
            setModalConfirm(false)
            navigate(DASHBOARD_ROUTER.path)
        } catch (error) {
            setEmailError('Código incorrecto')
            setExcededAttempts(true)
        } finally {
            setPending(false)
        }
    }

    return (
        <Modal header={modalCofirm ? 'Código Enviado' : 'Iniciar Sesión'} onClose={close}>
            <div className='modalLogin'>
                <Title center size='sm'>
                    {modalCofirm ? 'Verifica tu cuenta' : 'Entra a tu cuenta TierrasGP'}
                </Title>
                {!modalCofirm && (
                    <>
                        <div className='modalLogin_btns_social'>
                            <ButtonFacebook textShort onClick={signInFacebook} />
                            <ButtonGoogle textShort onClick={signInGoogle} />
                        </div>

                        {step === MODAL_PHONE && (
                            <div>
                                <PhoneInput value={phone} onChange={setPhone} />
                                {emailError && <p className='inputCustom_error' style={{ marginTop: 10 }}>{emailError}</p>}
                            </div>
                        )}

                        {step === MODAL_EMAIL && (
                            <Input
                                label='Email'
                                name={FIELD_KEY_EMAIL}
                                value={email}
                                placeholder='Email'
                                error={emailError}
                                onChange={e => setEmail(e.target.value)}
                            />
                        )}

                        <div className='modalLogin_btns'>
                            <Button pending={pending} size="xl" alignSelfStart rounded onClick={handleLogin}>
                                {step === MODAL_PHONE ? 'Entrar' : 'Comenzar con Email'}
                            </Button>

                            <Button
                                textColor='primary'
                                transparent
                                icon
                                underline
                                onClick={handleChangeStep}
                                colorIcon='primary'
                                jContentStart
                                fontWeight='600'
                            >
                                {step === MODAL_PHONE
                                    ? 'Iniciar sesión con Email'
                                    : 'Iniciar con celular'}
                                <ArrowRightIcon color='primary' />
                            </Button>
                        </div>
                    </>
                )}

                {modalCofirm && (
                    <ConfirmCode
                        step={step === MODAL_PHONE ? 'phone' : 'email'}
                        value={step === MODAL_PHONE ? phone : email}
                        onChange={setCode}
                        pending={pending}
                        error={emailError}
                        excededAttempts={excededAttempts}
                        onSuccess={handleConfirmOTP}
                    />
                )}
            </div>
        </Modal>
    )
}

Login.propTypes = {
    close: PropTypes.func,
}

export default Login
