import React from 'react'
import { Link } from 'react-router-dom'

// Components
import Logo from '../Logo/Logo'
import { LogoElemental } from '../Logo'
import { Title } from '../Title'

// Hooks
import { useLogin } from '../../hooks'

// Helpers
import { NAVBAR_ROUTER, LIST_ROUTER } from '../../helpers/constants/routes'
import { PHONE_NUMBER } from '../../helpers/constants/contact'
import { formatPhoneNumber } from '../../helpers/utils'

// Routes
import {
    TERMS_AND_CONDITIONS_ROUTER,
    PRIVACY_POLICY_ROUTER,
} from '../../helpers/constants/routes'

const Footer = () => {
    const { isLogged } = useLogin()

    return (
        <>
            <div className='container bg_secundary'>
                <div className='content footer'>
                    <Logo size='md' color='white' />

                    <div className='footer_navigate'>
                        <Title size='md' color='white'>
                            Te puede interesar
                        </Title>
                        {LIST_ROUTER[NAVBAR_ROUTER].map((item) => {
                            if (item.disabledAuth === isLogged) return ''

                            return (
                                <Link key={item.name} to={item.path}>
                                    {item.name}
                                </Link>
                            )
                        })}
                    </div>

                    <div className='footer_navigate'>
                        <Title size='md' color='white'>
                            Contacto
                        </Title>
                        <a href={`tel:+${PHONE_NUMBER}`}>
                            +{formatPhoneNumber({ phoneNumber: PHONE_NUMBER })}
                        </a>
                        <a href='/#'>Medellín - Colombia</a>

                        <Title size='md' color='white'>
                            Aliado
                        </Title>

                        <LogoElemental />
                    </div>
                </div>
            </div>
            <div className='container bg_grey'>
                <div className='content copyRight'>
                    <a href={TERMS_AND_CONDITIONS_ROUTER.path}>
                        {TERMS_AND_CONDITIONS_ROUTER.name}
                    </a>{' '}
                    -<a href={PRIVACY_POLICY_ROUTER.path}>{PRIVACY_POLICY_ROUTER.name}</a>
                </div>
            </div>
        </>
    )
}

export default Footer
