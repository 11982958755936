import React from 'react'
import Select, { components } from 'react-select'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const SelectCustom = ({
    onChange,
    value,
    icon,
    listOptions,
    placeholder,
    className,
    isMulti,
    defaultValue,
}) => {
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                {icon}
            </components.DropdownIndicator>
        )
    }

    const classSelect = clsx({
        [className]: className,
    })

    return (
        <Select
            onChange={onChange}
            value={value}
            components={{
                DropdownIndicator,
            }}
            placeholder={placeholder}
            className={classSelect}
            classNamePrefix='selectCustom'
            options={listOptions}
            isMulti={isMulti}
            defaultValue={defaultValue}
        />
    )
}

SelectCustom.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    icon: PropTypes.node,
    listOptions: PropTypes.array,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    isMulti: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
}

export default SelectCustom
