import { useState } from 'react'
import { API_PUBLIC } from '../service/api'

// Fields
import {
    FIELDS_PLACES_OF_INTEREST,
    FIELD_KEY_IMG_PRIMARY,
    FIELD_KEY_PROPERTIES,
    FIELD_KEY_IMG_HEADER,
} from '../utils/fields'

// Const
import { ORDER_BY_PUBLISHED_AT } from '../const/queryEndpoint'

const POPULATE = 'populate'
const ENDPOINT = '/sitios-de-interes'
const POPULATE_IMG_PRIMARY = `${POPULATE}[${FIELDS_PLACES_OF_INTEREST[FIELD_KEY_IMG_PRIMARY]}][${POPULATE}]`
const POPULATE_IMG_HEADER = `${POPULATE}[${FIELDS_PLACES_OF_INTEREST[FIELD_KEY_IMG_HEADER]}][${POPULATE}]`

const usePlacesOfInterest = () => {
    const [listPlacesOfPlaces, setListPlaces] = useState([])
    const [errorPlaces, setErrorPlaces] = useState('')
    const [loadingPLaces, setLoadingPlaces] = useState(true)
    const [placeOfInterest, setPlaceOfInterest] = useState({})

    // buscar las propiedades destacadas
    const getPlacesOfInterest = async ({ limit }) => {
        try {
            setLoadingPlaces(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}?${ORDER_BY_PUBLISHED_AT}&${POPULATE}=*&${limit && `pagination[limit]=${limit}`
                }`,
            })
            setListPlaces(res)
        } catch (error) {
            setErrorPlaces(error)
        } finally {
            setLoadingPlaces(false)
        }
    }

    const getPlaceOfInt = async ({ id }) => {
        try {
            setLoadingPlaces(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}/?filters[id][$eq]=${id}&${POPULATE_IMG_PRIMARY}&${POPULATE_IMG_HEADER}&${POPULATE}[${FIELDS_PLACES_OF_INTEREST[FIELD_KEY_PROPERTIES]}][${POPULATE}]=%2A&${POPULATE}[${FIELDS_PLACES_OF_INTEREST[FIELD_KEY_PROPERTIES]}][${FIELDS_PLACES_OF_INTEREST[FIELD_KEY_IMG_PRIMARY]}][${POPULATE}]`,
            })

            setPlaceOfInterest(res.data[0])
        } catch (error) {
            setErrorPlaces(error)
        } finally {
            setLoadingPlaces(false)
        }
    }

    return {
        listPlacesOfPlaces,
        errorPlaces,
        loadingPLaces,
        placeOfInterest,
        getPlacesOfInterest,
        getPlaceOfInt,
    }
}

export default usePlacesOfInterest
