import React from 'react'
import PropTypes from 'prop-types'

const CheckBox = ({ name, label, value, onChange, error }) => {
    return (
        <div>
            <label className='checkBox' htmlFor={name}>
                <input
                    type='checkbox'
                    checked={value}
                    name={name}
                    id={name}
                    onChange={onChange}
                />
                {label}
            </label>
            {error && <span className='inputCustom_error'>{error}</span>}
        </div>
    )
}

CheckBox.propTypes = {
    name: PropTypes.string, 
    label: PropTypes.string, 
    value: PropTypes.bool,
    onChange: PropTypes.func,
    error: PropTypes.string,
}

export default CheckBox
