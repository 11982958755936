import React from 'react'
import PropTypes from 'prop-types'

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const InputPhone = ({ value, error, disabled, onChange }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <span className='labelCustom'>Teléfono</span>
            <PhoneInput
                defaultCountry="CO"
                international
                value={value}
                onChange={onChange}
                disabled={disabled}
                style={{
                    background: 'white',
                    borderRadius: '4px',
                    border: '1px solid #cccccc',
                    height: '38px',
                    padding: '0 10px',
                }}
            />
            {error && <span className='inputCustom_error'>{error}</span>}
        </div>
    )
}

export default InputPhone

InputPhone.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
}

