import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete'

// Utils
import { getFullAddress } from '../../utils/helpers'

const AutoComplete = ({ onChange, placeholder }) => {
    const [address, setAddress] = useState('')

    const handleChange = (address) => {
        setAddress(address)
    }

    const handleSelect = async (address) => {
        try {
            const results = await geocodeByAddress(address)
            const latLng = await getLatLng(results[0])
            const addressData = getFullAddress({
                address: results[0].address_components,
            })

            onChange({ ...latLng, ...addressData, address })
            setAddress(address)
        } catch (e) {
            console.error('Error', e)
        }
    }

    return (
        <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className='autoComplete'>
                    <input
                        {...getInputProps({
                            placeholder,
                            className: 'location-search-input',
                        })}
                    />
                    <div className='autocomplete-dropdown-container'>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item'

                            return (
                                <div
                                    key={suggestion.placeId}
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}

AutoComplete.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
}

export default AutoComplete
