import React from 'react'
import PropTypes from 'prop-types'

// Icons
import { BedIcon, BathroomIcon, MetersIcon } from '../Svg'

const FeaturedPropertiesDetails = ({ bed, bathroom, meters }) => {
    return (
        <div className='featuredPropertiesDetails'>
            <div className='featuredPropertiesDetails_item'>
                <BedIcon color='#69A831' /> {bed}
            </div>
            <div className='featuredPropertiesDetails_item'>
                <BathroomIcon color='#69A831' />
                {bathroom}
            </div>
            <div className='featuredPropertiesDetails_item'>
                <MetersIcon color='#69A831' />
                <span>
                    {meters}m<sup>2</sup>
                </span>
            </div>
        </div>
    )
}

FeaturedPropertiesDetails.propTypes = {
    bed: PropTypes.number,
    bathroom: PropTypes.number,
    meters: PropTypes.number,
}

export default FeaturedPropertiesDetails
