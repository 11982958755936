import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../components/Title'
import { Text } from '../../../../components/Text'
import { Input } from '../../../../components/Input'
import { Button } from '../../../../components/Button'
import { SelectCustom } from '../../../../components/SelectCustom'

// Icons
import { LocationIcon, TypePropertieIcon } from '../../../../components/Svg'

// Const
import { LIST_TYPE_PROPERTY_SELECT } from '../../../../const/selectValues'

// Routes
import {
    PROPERTIES_ROUTER,
    LOTES_ROUTER,
} from '../../../../helpers/constants/routes'

// Fields
import {
    FIELD_KEY_LOCATION,
    FIELD_KEY_TYPE,
    FIELD_KEY_PROPERTY_CODE,
} from '../../../../utils/fields'

const HeroImg = ({ header, image, optionsMunicipality }) => {
    const [location, setLocation] = useState('')
    const [type, setType] = useState('')
    const [propertyCode, setPropertyCode] = useState('')
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault()

        let uriFilter = []
        if (location) uriFilter.push(`${FIELD_KEY_LOCATION}=${location}`)
        if (type) uriFilter.push(`${FIELD_KEY_TYPE}=${type}`)
        if (propertyCode)
            uriFilter.push(`${FIELD_KEY_PROPERTY_CODE}=${propertyCode}`)

        if (type === 'Lotes')
            return navigate(
                `${LOTES_ROUTER.path}${uriFilter.length > 0 ? `?${uriFilter.join('&')}` : ''
                }`,
            )

        navigate(
            `${PROPERTIES_ROUTER.path}${uriFilter.length > 0 ? `?${uriFilter.join('&')}` : ''
            }`,
        )
    }

    return (
        <div className='hero_img'>
            <img src={image} alt='hero img' />
            <div className='hero_img_content'>
                <Title color='white' size='xl'>
                    {header}
                </Title>

                <form
                    onSubmit={handleSubmit}
                    className='hero_img_content_search_properties bg_grey'
                >
                    <SelectCustom
                        onChange={(e) => setLocation(e.value)}
                        value={optionsMunicipality.find((item) => item.value === location)}
                        placeholder='Municipio'
                        listOptions={optionsMunicipality}
                        className='hero_img_select'
                        icon={<LocationIcon />}
                    />

                    <SelectCustom
                        onChange={(e) => setType(e.value)}
                        value={LIST_TYPE_PROPERTY_SELECT.find(
                            (item) => item.value === type,
                        )}
                        placeholder='Tipo de propiedad'
                        className='hero_img_select'
                        listOptions={LIST_TYPE_PROPERTY_SELECT}
                        icon={<TypePropertieIcon />}
                    />

                    <Input
                        placeholder='Código'
                        name={FIELD_KEY_PROPERTY_CODE}
                        value={propertyCode}
                        iconEnd={<LocationIcon />}
                        onChange={(e) => setPropertyCode(e.target.value)}
                    />

                    <Button
                        color='primary'
                        size='md'
                        rounded
                        alignSelfCenter
                        onlyIconMobile
                        className='hero_img_btnSearch'
                        type='submit'
                    >
                        <Text size='sm' color='white'>
                            Buscar propiedad
                        </Text>
                    </Button>
                </form>
            </div>
        </div>
    )
}

HeroImg.propTypes = {
    header: PropTypes.string,
    image: PropTypes.string,
    optionsMunicipality: PropTypes.array,
}

export default HeroImg
