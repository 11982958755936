import * as React from 'react'
import PropTypes from 'prop-types'

const OrderIcon = ({ color = '#69A831' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <g>
            <g>
                <path
                    style={{
                        fill: 'none',
                    }}
                    d='M-4.79-4.79h33.58v33.58H-4.79z'
                />
                <path
                    fill={color}
                    d='M10.6 5.24a.63.63 0 0 1-.46.2.42.42 0 0 1-.45-.14L7 2.5v20.59a.63.63 0 0 1-.59.65h-.08a.67.67 0 0 1-.64-.65V2.43l-2.8 2.8a.65.65 0 0 1-.91 0H2A.61.61 0 0 1 1.86 5a.45.45 0 0 1 0-.23.53.53 0 0 1 0-.25.43.43 0 0 1 .14-.21L5.87.43A.61.61 0 0 1 6.4.28a.66.66 0 0 1 .48.18l3.86 3.87a.41.41 0 0 1 .14.21 1.13 1.13 0 0 1 0 .26.53.53 0 0 1 0 .25.61.61 0 0 1-.28.19Z'
                />
                <path
                    fill={color}
                    d='M13.4 18.74a.74.74 0 0 1 .47-.18h.24a.62.62 0 0 1 .2.16l2.8 2.79V.89a.66.66 0 0 1 .19-.46.67.67 0 0 1 .46-.18.64.64 0 0 1 .45.19.69.69 0 0 1 .2.45v20.67l2.8-2.8a.62.62 0 0 1 .88 0 .49.49 0 0 1 .14.21.45.45 0 0 1 0 .23.57.57 0 0 1 0 .26.49.49 0 0 1-.14.21l-3.87 3.87a.56.56 0 0 1-.2.14h-.23a.57.57 0 0 1-.26 0 .74.74 0 0 1-.22-.14l-3.91-3.85a.6.6 0 0 1-.14-.21.62.62 0 0 1 0-.53.7.7 0 0 1 .14-.21Z'
                />
            </g>
        </g>
    </svg>
)

OrderIcon.propTypes = {
    color: PropTypes.string,
}

export default OrderIcon
