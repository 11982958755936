import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import {
    FeaturedPropertiesDetails,
    FeaturedPropertiesLocation,
    FeaturedPropertiesPrice,
} from '../FeaturedPropertiesList'
import { Text } from '../Text'

import ImagePlaceholder from '../../assets/images/image-placeholder.png'

import { PROPERTY_ROUTER } from '../../helpers/constants/routes'

const UserPropertySale = ({
    item,
    shadow = true,
}) => {
    const classFeatured = clsx('featuredPropertiesLarge', {
        'featuredPropertiesLarge_shadow': shadow,
    })

    const getImage = () => {
        if (item?.Imagen_Principal) {
            return item?.Imagen_Principal?.data?.attributes?.url
        }

        return ImagePlaceholder
    }

    return (
        <div className='propertiesLarge'>
            <Link to={item?.publishedAt ? `${PROPERTY_ROUTER.path}/${item?.id}` : ''} className={classFeatured} style={{ position: 'relative' }}>
                <div className='featuredPropertiesLarge_image'>
                    <img src={getImage()} alt='Images' />
                    <div className='savePropertieLabel'>
                        <Text size='sm' color='primary'>
                            Pendiente de publicación
                        </Text>
                    </div>
                </div>

                <div className='featuredPropertiesLarge_content'>
                    {item?.Nombre && (
                        <Text bold color='primary'>
                            {item?.Nombre}
                        </Text>
                    )}
                    <FeaturedPropertiesDetails
                        bed={'-'}
                        bathroom={'-'}
                        meters={'-'}
                    />

                    <FeaturedPropertiesLocation location={item?.Ubicacion || '-'} />

                    <FeaturedPropertiesPrice price={item?.Precio || '---------'} />
                </div>
            </Link>
        </div>
    )
}

UserPropertySale.propTypes = {
    item: PropTypes.object,
    shadow: PropTypes.bool,
}

export default UserPropertySale
