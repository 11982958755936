import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

// Components
import { Text } from '../Text'
import { Title } from '../Title'
import { Button } from '../Button'
import { RenderMarkDown } from '../RenderMarkDown'

const FeaturedPost = ({ image, date, title, description, goTo, button }) => {
    return (
        <div className='featuredPost'>
            <img src={image} alt='places and sectos' />

            <div className='featuredPost_content'>
                <Text size='sm'>{moment(date).format('DD / MM / YYYY')}</Text>
                <Title size='lg'>{title}</Title>
                <RenderMarkDown>{description}</RenderMarkDown>
                <Button to={goTo} rounded>
                    {button}
                </Button>
            </div>
        </div>
    )
}

FeaturedPost.propTypes = { 
    image: PropTypes.string, 
    date: PropTypes.string, 
    title: PropTypes.string, 
    description: PropTypes.string, 
    goTo: PropTypes.string, 
    button: PropTypes.string, 
}

export default FeaturedPost
