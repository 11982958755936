export const LIST_TYPE_PROPERTY_SELECT = [
    { value: 'Casa Campestre', label: 'Casa Campestre' },
    { value: 'Casa con condominio', label: 'Casa con condominio' },
    { value: 'Finca', label: 'Finca' },
    { value: 'Finca Agrícola', label: 'Finca Agrícola' },
    { value: 'Finca de recreo', label: 'Finca de recreo' },
    { value: 'Lotes', label: 'Lotes' },
]

export const LIST_ORDER_BY = [
    { value: null, label: 'Ordenar por', isDisabled: true },
    { value: '', label: 'Más reciente' },
    { value: 'asc', label: 'Precio: menor a mayor' },
    { value: 'desc', label: 'Precio: mayor a menor' },
]
