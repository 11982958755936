import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../components/Title'
import { PlacesAndSectorsList } from '../../../../components/PlacesAndSectorsList'
import { FeaturedPost } from '../../../../components/FeaturedPost'
import { RenderMarkDown } from '../../../../components/RenderMarkDown'

// Utils
import { FIELDS_PLACES_OF_INTEREST } from '../../../../utils/fields'

// Routes
import { PLACES_OF_INTEREST_ROUTER } from '../../../../helpers/constants/routes'

const PlacesAndSectors = ({ data, title, description }) => {
    const principalPlaces = data[0]
    const secundaryPlaces = data.filter((item) => item.id !== principalPlaces.id)

    return (
        <div className='container'>
            <div className='content placesAndSectors'>
                <Title size='lg'>{title}</Title>
                <RenderMarkDown>{description}</RenderMarkDown>

                <FeaturedPost
                    image={
                        principalPlaces.attributes[FIELDS_PLACES_OF_INTEREST.imgPrimary]
                            .data.attributes.url
                    }
                    title={principalPlaces.attributes[FIELDS_PLACES_OF_INTEREST.name]}
                    description={
                        principalPlaces.attributes[FIELDS_PLACES_OF_INTEREST.description]
                    }
                    date={principalPlaces.attributes[FIELDS_PLACES_OF_INTEREST.create]}
                    button='Ver más'
                    goTo={`${PLACES_OF_INTEREST_ROUTER.path}/${principalPlaces.id}`}
                />

                <PlacesAndSectorsList data={secundaryPlaces} />
            </div>
        </div>
    )
}

PlacesAndSectors.propTypes = { 
    data: PropTypes.array, 
    title: PropTypes.string, 
    description: PropTypes.string, 
}

export default PlacesAndSectors
