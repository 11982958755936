import * as React from 'react'
import PropTypes from 'prop-types'

const CloudIcon = ({ color = '#fff' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <g>
            <path
                d='M11.41 20.77a8.791 8.791 0 1 1 8.44-6.51L21.07 16a1.64 1.64 0 0 1-.61 2.39 1.57 1.57 0 0 1-.9.17l-2.13-.23a8.67 8.67 0 0 1-6.02 2.44Zm0-16.61a8 8 0 0 0-3.7.92 7.861 7.861 0 0 0 5.97 14.46 8 8 0 0 0 3.27-2l.13-.16 2.55.26a.65.65 0 0 0 .39-.07.75.75 0 0 0 .29-.27.67.67 0 0 0 .1-.39.77.77 0 0 0-.12-.38l-1.44-2.14.07-.24a7.75 7.75 0 0 0-7.49-10Z'
                fill={color}
            />
            <path
                d='M8.15 11.07h-.92a.46.46 0 0 0-.46.46v.93a.46.46 0 0 0 .46.46h.92a.46.46 0 0 0 .46-.46v-.93a.46.46 0 0 0-.46-.46Z'
                fill={color}
            />
            <path
                d='M11.84 11.07h-.92a.46.46 0 0 0-.46.46v.93a.46.46 0 0 0 .46.46h.92a.46.46 0 0 0 .46-.46v-.93a.46.46 0 0 0-.46-.46Z'
                fill={color}
            />
            <path
                d='M15.53 11.07h-.92a.47.47 0 0 0-.46.46v.93a.47.47 0 0 0 .46.46h.92a.47.47 0 0 0 .47-.46v-.93a.47.47 0 0 0-.47-.46Z'
                fill={color}
            />
        </g>
    </svg>
)

CloudIcon.propTypes = {
    color: PropTypes.string,
}

export default CloudIcon
