import { useState } from 'react'
import { API_PUBLIC } from '../service/api'

// Hooks
import { usePagination } from './'

// Fields
import {
    FIELDS_BLOG,
    FIELD_KEY_IMG_HEADER,
    FIELD_KEY_IMG_PRIMARY,
    FIELD_KEY_PROPERTIES,
    FIELD_KEY_FEATURED_PROPERTY,
} from '../utils/fields'

// Const
import { ORDER_BY_PUBLISHED_AT } from '../const/queryEndpoint'

const POPULATE = 'populate'
const POPULATE_IMG_PRIMARY = `${POPULATE}[${FIELDS_BLOG[FIELD_KEY_IMG_PRIMARY]}][${POPULATE}]`
const POPULATE_IMG_HEADER = `${POPULATE}[${FIELDS_BLOG[FIELD_KEY_IMG_HEADER]}][${POPULATE}]`
const ENDPOINT = '/blogs'
const ITEM_FOR_PAGE = 5

const useBlog = () => {
    const [listBlog, setListBlog] = useState([])
    const [errorBlog, setErrorBlog] = useState('')
    const [loadingBlog, setLoadingBlog] = useState(true)
    const [firstBlog, setFirstBlog] = useState({})
    const [postBlog, setPostBlog] = useState({})
    const { totalPage, page, handleChangePage, handleConfigPage } =
        usePagination()

    // buscar los post destacadas
    const getBlogsOutstanding = async () => {
        try {
            setLoadingBlog(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}/?${POPULATE_IMG_PRIMARY}&filters[${FIELDS_BLOG[FIELD_KEY_FEATURED_PROPERTY]}][$eq]=true&pagination[limit]=3&${ORDER_BY_PUBLISHED_AT}`,
            })
            setListBlog(res)
        } catch (error) {
            setErrorBlog(error)
        } finally {
            setLoadingBlog(false)
        }
    }

    const getBlogs = async () => {
        try {
            setLoadingBlog(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}/?${POPULATE_IMG_PRIMARY}&pagination[pageSize]=${ITEM_FOR_PAGE}&pagination[page]=${page}&${ORDER_BY_PUBLISHED_AT}`,
            })

            if (totalPage !== res.meta.pagination.total)
                handleConfigPage({
                    totalItem: res.meta.pagination.total,
                    itemForPage: ITEM_FOR_PAGE,
                })

            setFirstBlog(res.data[0])
            setListBlog(res.data.slice(1, ITEM_FOR_PAGE))
        } catch (error) {
            setErrorBlog(error)
        } finally {
            setLoadingBlog(false)
        }
    }

    const getBlog = async ({ id }) => {
        try {
            setLoadingBlog(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}/?filters[id][$eq]=${id}&${POPULATE_IMG_PRIMARY}&${POPULATE_IMG_HEADER}&${POPULATE}[${FIELDS_BLOG[FIELD_KEY_PROPERTIES]}][${POPULATE}]=%2A&${POPULATE}[${FIELDS_BLOG[FIELD_KEY_PROPERTIES]}][${FIELDS_BLOG[FIELD_KEY_IMG_PRIMARY]}][${POPULATE}]`,
            })

            setPostBlog(res.data[0])
        } catch (error) {
            setErrorBlog(error)
        } finally {
            setLoadingBlog(false)
        }
    }

    return {
        firstBlog,
        listBlog,
        errorBlog,
        loadingBlog,
        totalPage,
        page,
        postBlog,
        getBlogsOutstanding,
        handleChangePage,
        getBlogs,
        getBlog,
    }
}

export default useBlog
