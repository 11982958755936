import { useState, useCallback } from 'react'
import { API_PUBLIC } from '../service/api'

// Hooks
import { useDebounce, usePagination } from './'

// Utils
import {
    FIELD_LOTES,
    FIELD_ORDER_BY,
    FIELD_KEY_LOCATION,
    FIELD_KEY_PRICE,
} from '../utils/fields'

// Const
import { MIN_PRICE } from '../const/inputRange'
import { ORDER_BY_PUBLISHED_AT } from '../const/queryEndpoint'
import { LIST_ORDER_BY } from '../const/selectValues'

const POPULATE = 'populate'
const ENDPOINT = '/lotes'
const POPULATE_MUNICIPALITY = `${POPULATE}[${FIELD_LOTES.location}][${POPULATE}]`
const POPULATE_IMG_PRIMARY = `${POPULATE}[${FIELD_LOTES.imgPrimary}][${POPULATE}]`
const POPULATE_GALERY = `${POPULATE}[${FIELD_LOTES.galery}][${POPULATE}]`
const POPULATE_PLACES_OF_INTEREST = `${POPULATE}[${FIELD_LOTES.placesOfInterest}][${POPULATE}]=%2A`
const POPULATE_FEATURES = `${POPULATE}[${FIELD_LOTES.features}][${POPULATE}]=`
const ITEM_FOR_PAGE = 10

const useLotes = () => {
    const [listLotes, setListLotes] = useState({})
    const [loteInfo, setLoteInfo] = useState({})
    const [errorLotes, setErrorLotes] = useState('')
    const [loadingLotes, setLoadingLotes] = useState(true)
    const [dataFilterLotes, setDataFilterLotes] = useState({
        [FIELD_ORDER_BY]: LIST_ORDER_BY[1],
    })
    const { totalPage, page, handleChangePage, handleConfigPage } =
        usePagination()

    // buscar un lote por id
    const getLote = async ({ id }) => {
        try {
            setLoadingLotes(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}/${id}?${POPULATE_PLACES_OF_INTEREST}&${POPULATE_FEATURES}&${POPULATE_GALERY}&${POPULATE_IMG_PRIMARY}&${POPULATE_MUNICIPALITY}`,
            })
            setLoteInfo(res.data)
        } catch (error) {
            setErrorLotes(error)
        } finally {
            setLoadingLotes(false)
        }
    }

    // Buscar todos los lotes
    const getLotes = async ({ filter = '' } = {}) => {
        try {
            setLoadingLotes(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}?${filter || `${createUriPagination({ page: 1 })}&${ORDER_BY_PUBLISHED_AT}`
                }&${POPULATE_IMG_PRIMARY}&${POPULATE_MUNICIPALITY}`,
            })

            if (totalPage !== res.meta.pagination.total) {
                handleConfigPage({
                    totalItem: res.meta.pagination.total,
                    itemForPage: ITEM_FOR_PAGE,
                })
            }

            setListLotes(res)
            return res
        } catch (error) {
            setErrorLotes(error)
        } finally {
            setLoadingLotes(false)
        }
    }

    // Filtrar la lista
    const createUriPagination = ({ page }) => {
        return `pagination[pageSize]=${ITEM_FOR_PAGE}&pagination[page]=${page}`
    }

    // Filter between
    const createUriFilterBetween = ({ item, datafilter }) => {
        return Object.keys(datafilter[item])
            .map(
                (itemRange) =>
                    `filters[${FIELD_LOTES[item]}][$between]=${datafilter[item][itemRange] || 0
                    }`,
            )
            .join('&')
    }

    const createUriFilter = ({ datafilter, page }) => {
        return [
            ...Object.keys(datafilter).map((item) => {
                // Filters key $contains
                if (FIELD_KEY_LOCATION === item) {
                    if (Array.isArray(datafilter[item])) {
                        return datafilter[item]
                            .map(
                                (filter) =>
                                    `filters[${FIELD_LOTES[item]}][Nombre][$eq]=${filter.value}`,
                            )
                            .join('&')
                    }

                    return `filters[${FIELD_LOTES[item]}][Nombre][$contains]=${datafilter[item]}`
                }
                
                if (FIELD_LOTES.code === item) return `filters[${FIELD_LOTES.code}][$contains]=${datafilter[item]}`

                // Filter key $between
                if (
                    (datafilter[item]?.max > 0 && datafilter[item]?.min > 0) &&
                    FIELD_KEY_PRICE === item
                ) {
                    // PRICE
                    if (
                        datafilter[item]?.max - 1 > MIN_PRICE
                    ) {
                        return createUriFilterBetween({ datafilter, item })
                    }

                    if (
                        datafilter[item]?.max - 1 !== datafilter[item]?.min
                    ) {
                        return createUriFilterBetween({ datafilter, item })
                    }
                }

                // Order by
                if (
                    item === FIELD_ORDER_BY &&
                    datafilter[FIELD_ORDER_BY].value !== ''
                ) {
                    return `sort=${FIELD_LOTES.price}%3A${datafilter[FIELD_ORDER_BY].value}`
                }

                return ORDER_BY_PUBLISHED_AT
            }),
            createUriPagination({ page }),
        ]
            .filter(Boolean)
            .join('&')
    }

    const filterLotes = ({ field, value }) => {
        const filter = {
            ...dataFilterLotes,
            [field]: value,
        }

        const urlFilter = createUriFilter({ datafilter: filter, page: 1 })

        handleChangePage({ page: 1 })
        setDataFilterLotes(filter)

        filterDebounce({ urlFilter })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filterDebounce = useCallback(
        useDebounce(({ urlFilter }) => {
            getLotes({ filter: urlFilter })
        }, 1000),
        [],
    )

    const handleChangePageLote = async ({ page }) => {
        handleChangePage && handleChangePage({ page })
        filterDebounce({
            urlFilter: createUriFilter({
                datafilter: dataFilterLotes,
                page,
            }),
        })
    }

    const defaultFilter = async ({ data }) => {
        setDataFilterLotes(data)
        return createUriFilter({ datafilter: data, page: 1 })
    }

    return {
        loteInfo,
        listLotes,
        errorLotes,
        loadingLotes,
        dataFilterLotes,
        totalPage,
        page,
        getLote,
        getLotes,
        filterLotes,
        handleChangePageLote,
        defaultFilter,
    }
}

export default useLotes