import { React } from 'react'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../components/Title'
import { RenderMarkDown } from '../../../../components/RenderMarkDown'
import { LoadingPolicy } from '../../../../components/Loading'


const RenderPolicy = ({ title, content, loading }) => {
    if (loading) return <LoadingPolicy />

    return (
        <div className="container">
            <div className="content policy">
                <Title center size='lg'>{title}</Title>
                <RenderMarkDown>{content}</RenderMarkDown>
            </div>
        </div>
    )
}

RenderPolicy.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    loading: PropTypes.bool,
}

export default RenderPolicy