import React from 'react'
import PropTypes from 'prop-types'

const Text = ({
    children,
    size = 'md',
    color = 'black',
    classNameCustom,
    bold,
    underline,
    pointer,
    center,
}) => {
    const sizeElements = {
        xl: 'xl',
        lg: 'lg',
        md: 'md',
        sm: 'sm',
        xs: 'xs',
    }

    return React.createElement(
        'span',
        {
            className: `textColor_${color} fontText_${sizeElements[size]} ${
                classNameCustom || ''
            } ${bold ? 'textBold' : ''} ${underline ? 'textUnderline' : ''} ${
                pointer ? 'textPointer' : ''
            } ${center ? 'textCenter' : ''}`,
        },
        children,
    )
}

Text.propTypes = {
    children: PropTypes.node,
    size: PropTypes.string,
    color: PropTypes.string,
    classNameCustom: PropTypes.string,
    bold: PropTypes.bool,
    underline: PropTypes.bool,
    pointer: PropTypes.bool,
    center: PropTypes.bool,
}

export default Text
