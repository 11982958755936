import React from 'react'
import PropType from 'prop-types'

// Components
import { Title } from '../Title'
import { RenderMarkDown } from '../RenderMarkDown'

const FeaturedContainer = ({ title, description, children }) => {
    return (
        <div className='container'>
            <div className='content featuredProperties'>
                <Title size='lg'>{title}</Title>
                <RenderMarkDown>{description}</RenderMarkDown>

                <div className='featuredProperties_list'>
                    {children}
                </div>
            </div>
        </div>
    )
}

FeaturedContainer.propTypes = {
    title: PropType.string,
    description: PropType.string,
    children: PropType.node,
}

export default FeaturedContainer
