import React from 'react'
import PropTypes from 'prop-types'

// Component
import { PropertiesCard } from './'

// Utils
import { FIELDS_PROPERTY } from '../../utils/fields'

// Route
import { PROPERTY_ROUTER } from '../../helpers/constants/routes'

const FeaturedPropertiesList = ({ data }) => {
    return (
        <>
            {data.map((item) => (
                <PropertiesCard
                    id={item.id}
                    key={item.id}
                    save={item.attributes.save}
                    bed={item.attributes[FIELDS_PROPERTY.rooms]}
                    bathroom={item.attributes[FIELDS_PROPERTY.bathrooms]}
                    meters={item.attributes[FIELDS_PROPERTY.totalArea]}
                    name={item.attributes[FIELDS_PROPERTY.name]}
                    location={
                        item.attributes[FIELDS_PROPERTY.location]?.data?.attributes?.Nombre || ''
                    }
                    price={item.attributes[FIELDS_PROPERTY.price]}
                    image={
                        item.attributes[FIELDS_PROPERTY.imgPrimary].data.attributes.url
                    }
                    goTo={`${PROPERTY_ROUTER.path}/${item.id}`}
                />
            ))}
        </>
    )
}

FeaturedPropertiesList.propTypes = {
    data: PropTypes.array,
}

export default FeaturedPropertiesList
