import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Modal } from '../../../../components/Modal'
import { StepOne, StepTwo } from './Steps'

import { USER_SELL } from '../../../../const/typeUser'

const ModalRegister = ({ loading, step, close, onRegister }) => {
    const [registerData, setRegisterData] = React.useState({})
    const [stepShowInfo, setStepShowInfo] = React.useState(false)

    const handleUpdateRegisterData = ({ data }) => {
        setRegisterData(data)

        if (step === USER_SELL) {
            setStepShowInfo(true)
        }
    }

    const handleFinishSteps = () => {
        onRegister({ data: registerData })
    }

    return (
        <Modal
            onClose={loading ? undefined : close}
            header={
                step === USER_SELL ? 'Agrega Tu Propiedad En Venta' : 'Confirma Tu Información'
            }
        >
            <div className='modalRegister modal-md'>
                {step === USER_SELL &&
                    !stepShowInfo ? (
                        <StepOne
                            goBack={close}
                            onChange={handleUpdateRegisterData}
                        />
                    ) : (
                        <StepTwo
                            data={registerData}
                            goBack={() => setStepShowInfo(false)}
                            loading={loading}
                            onSubmit={handleFinishSteps}
                        />
                    )}
            </div>
        </Modal>
    )
}

ModalRegister.propTypes = {
    close: PropTypes.func,
    onRegister: PropTypes.func,
    step: PropTypes.string,
    loading: PropTypes.bool,
}

export default ModalRegister
