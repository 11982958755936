import * as React from 'react'

const TargetIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 95 95'>
        <rect
            width={95}
            height={95}
            rx={11.4}
            style={{
                fill: '#69A831',
            }}
        />
        <g>
            <g>
                <path
                    style={{
                        fill: 'none',
                    }}
                    d='M17.11 17.11h60.8v60.8h-60.8z'
                />
                <path
                    d='M70.55 24.52a1.14 1.14 0 0 0-1.61 0L64 29.46v-3.61a1 1 0 0 0-.32-.72 1.12 1.12 0 0 0-1.57 0 1.15 1.15 0 0 0-.31.75v5.73l-1.63 1.66a21.87 21.87 0 1 0 1.61 1.63l1.78-1.78h5.85a1.21 1.21 0 0 0 .79-.32 1.08 1.08 0 0 0 .35-.79 1.7 1.7 0 0 0 0-.45.84.84 0 0 0-.25-.37.91.91 0 0 0-.37-.24.75.75 0 0 0-.42 0h-3.9l4.94-4.94a1.14 1.14 0 0 0 .27-.76 1.06 1.06 0 0 0-.27-.76Zm-5.63 24.84A19.34 19.34 0 1 1 58.47 35L56 37.43a16 16 0 1 0 5.46 12 15.72 15.72 0 0 0-3.85-10.35l2.47-2.47a19 19 0 0 1 4.84 12.75Zm-20.17 1a1.16 1.16 0 0 0 .79.33 1.22 1.22 0 0 0 .81-.33l1.43-1.4a1.09 1.09 0 0 1 0 .44 2.41 2.41 0 0 1-.42 1.33 2.24 2.24 0 0 1-2.47.84 2.12 2.12 0 0 1-1.14-.81 2.21 2.21 0 0 1-.44-1.33 2.41 2.41 0 0 1 .42-1.33 2.14 2.14 0 0 1 1.14-.84 2.27 2.27 0 0 1 1.41 0l-1.51 1.5a1.14 1.14 0 0 0 0 1.61Zm.81-5.53a4.72 4.72 0 0 0-2.77 1 4.59 4.59 0 0 0-1.6 2.47 4.77 4.77 0 0 0 .19 2.94 4.62 4.62 0 0 0 4.95 2.75A4.45 4.45 0 0 0 49 52.6a4.62 4.62 0 0 0 1.14-2.6 4.51 4.51 0 0 0-.6-2.87L52 44.64a7.74 7.74 0 0 1 1.56 4.55A8 8 0 0 1 44 57a7.86 7.86 0 0 1-4.14-2.12 8 8 0 0 1-1.73-8.7 7.76 7.76 0 0 1 2.94-3.65 7.85 7.85 0 0 1 9.26.32l-2.47 2.47a4.37 4.37 0 0 0-2.32-.57Zm6.37-3.38a10 10 0 0 0-6.37-2.3 10.37 10.37 0 1 0 7.78 4l2.47-2.47A12.07 12.07 0 1 1 54.38 39Z'
                    style={{
                        fill: '#fff',
                    }}
                />
            </g>
        </g>
    </svg>
)

export default TargetIcon
