import React from 'react'

// Icons
import { FacebookShareIcon } from '../Svg'

// Utils
import { createUrlShare } from '../../utils/helpers'

const FacebookShare = () => {
    return (
        <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${createUrlShare()};src=sdkpreparse`}
            target='_blank'
            rel='noreferrer'
            className='displayFlex'
        >
            <FacebookShareIcon />
        </a>
    )
}

export default FacebookShare
