// SECTION
export const NAVBAR_ROUTER = 'navbar'
export const NAVIGATE_DASHBOARD = 'dashboard'

// ROUTER
export const HOME_ROUTER = {
    name: 'Inicio',
    path: '/',
}
export const PROPERTIES_ROUTER = {
    name: 'Propiedades',
    path: '/propiedades',
}
export const PROPERTY_ROUTER = {
    name: 'Propiedad',
    path: '/propiedad',
}
export const LOTES_ROUTER = {
    name: 'Lotes',
    path: '/lotes',
}
export const LOTE_ROUTER = {
    name: 'Lote',
    path: '/lote',
}
export const PARCELLINGS_ROUTER = {
    name: 'Parcelaciones',
    path: '/parcelaciones',
}
export const PARCELLING_ROUTER = {
    name: 'Parcelación',
    path: '/parcelacion',
}
export const REGISTER_ROUTER = {
    name: 'Registrarme',
    path: '/register',
}
export const SELLS_ROUTER = {
    name: 'Vende',
    path: REGISTER_ROUTER.path,
    disabledAuth: true,
}
export const ABOUTUS_ROUTER = {
    name: 'Nosotros',
    path: '/nosotros',
}
export const BLOG_ROUTER = {
    name: 'Blog',
    path: '/blog',
}
export const BLOG_POST_ROUTER = {
    name: 'Blog Post',
    path: '/blog/post',
}

export const PLACES_OF_INTEREST_ROUTER = {
    name: 'Sitio de Interes',
    path: '/sitio-de-interes',
}

export const DASHBOARD_ROUTER = {
    name: 'Dashboard',
    path: '/dashboard',
}

export const DASHBOARD_PROFILE_ROUTER = {
    name: 'Dashboard',
    path: '/dashboard',
}

export const DASHBOARD_PROPERTIES_ROUTER = {
    name: 'Propiedades',
    path: `${DASHBOARD_ROUTER.path}/properties`,
}

export const DASHBOARD_FAVORITE_ROUTER = {
    name: 'Favoritos',
    path: `${DASHBOARD_ROUTER.path}/favorites`,
}

export const DESING_AND_CONSTRUCTION_ROUTER = {
    name: 'Diseño y Construcción',
    path: '/diseno-construccion',
}

export const PRIVACY_POLICY_ROUTER = {
    name: 'Políticas de privacidad',
    path: '/politica-de-privacidad',
}

export const TERMS_AND_CONDITIONS_ROUTER = {
    name: 'Términos y condiciones',
    path: '/terminos-y-condiciones',
}

// LIST ROUTER
export const LIST_ROUTER = {
    [NAVBAR_ROUTER]: [
        PROPERTIES_ROUTER,
        PARCELLINGS_ROUTER,
        LOTES_ROUTER,
        DESING_AND_CONSTRUCTION_ROUTER,
        ABOUTUS_ROUTER,
        BLOG_ROUTER,
    ],
    [NAVIGATE_DASHBOARD]: [
        DASHBOARD_ROUTER,
        DASHBOARD_PROPERTIES_ROUTER,
        DASHBOARD_FAVORITE_ROUTER,
    ],
}
