import { useState } from 'react'
import { API_PUBLIC } from '../service/api'

const ENDPOINT = '/caracteristicas'

const useFeatures = () => {
    const [listFeatures, setListFeatures] = useState({})
    const [errorFeatures, setErrorFeatures] = useState('')
    const [loadingFeatures, setLoadingFeatures] = useState(false)

    // buscar las propiedades destacadas
    const getFeatures = async () => {
        try {
            setLoadingFeatures(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}`,
            })
            setListFeatures(res)
        } catch (error) {
            setErrorFeatures(error)
        } finally {
            setLoadingFeatures(false)
        }
    }

    return {
        listFeatures,
        errorFeatures,
        loadingFeatures,
        getFeatures,
    }
}

export default useFeatures
