import * as React from 'react'
import PropTypes from 'prop-types'

const MetersIcon = ({ color }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21.1 21.1'>
        <g
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={1.1}
        >
            <path d='M.55 10.55v-10h10' />
            <path d='M20.55 10.55v10h-10' />
        </g>
    </svg>
)

MetersIcon.propTypes = {
    color: PropTypes.string,
}

export default MetersIcon
