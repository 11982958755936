import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Text } from '../Text'
import { PropertiesInterested } from './'

const PropertiesInterestedList = ({ interested }) => {
    return (
        <div className='propertiesInterestedList'>
            <Text bold>Interesados</Text>
            {interested.map((item, index) => (
                <PropertiesInterested
                    key={index}
                    image={item.image}
                    user={item.name}
                    email={item.email}
                />
            ))}
        </div>
    )
}

PropertiesInterestedList.propTypes = {
    interested: PropTypes.array,
}

export default PropertiesInterestedList
