import React from 'react'
import PropTypes from 'prop-types'

// Componetns
import { Text } from '../Text'

// Icon
import { FacebookLogo } from '../Svg'

const ButtonFacebook = ({ textShort, onClick }) => {
    return (
        <button onClick={onClick} className='buttonFacebook'>
            <FacebookLogo />
            <Text>{textShort ? 'Facebook' : 'Registro con facebook'}</Text>
        </button>
    )
}

ButtonFacebook.propTypes = {
    textShort: PropTypes.bool,
    onClick: PropTypes.func,
}

export default ButtonFacebook
