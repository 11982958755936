import React, { useEffect } from 'react'

// Components
import { FeaturedPost } from '../../components/FeaturedPost'
import { ListPost } from './components/ListPost'
import { BlogHero } from './components/BlogHero'
import Pagination from '../../components/Pagination/Pagination'
import { LoadingHeroBlog } from '../../components/Loading'

// Fields
import {
    FIELDS_BLOG,
    FIELD_KEY_DESCRIPTION,
    FIELD_KEY_IMG_PRIMARY,
    FILED_KEY_TITLE,
    FIELD_KEY_CREATED,
} from '../../utils/fields'

// Hooks
import { useBlog } from '../../hooks'

// Routes
import { BLOG_POST_ROUTER, HOME_ROUTER } from '../../helpers/constants/routes'

// Image
import ImageHero from '../../assets/images/blog.png'

// Helpers
import { scrollTop } from '../../helpers/utils'

const Blog = () => {
    const {
        firstBlog,
        listBlog,
        loadingBlog,
        totalPage,
        page,
        getBlogs,
        handleChangePage,
    } = useBlog()

    const getListBlog = async () => {
        try {
            await getBlogs()
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        scrollTop()
        getListBlog()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <div className='blog'>
            <BlogHero
                image={ImageHero}
                title='Sectores Recomendados'
                description='Explore todos los articulos relacionados con los mejores sectores para vivir en Medellin-Antioquia.'
                goBack={HOME_ROUTER.path}
            />

            <div className='container'>
                <div className='content blog_content'>
                    {loadingBlog && <LoadingHeroBlog />}

                    {!loadingBlog && firstBlog && (
                        <>
                            <FeaturedPost
                                image={
                                    firstBlog.attributes[FIELDS_BLOG[FIELD_KEY_IMG_PRIMARY]].data
                                        .attributes.url
                                }
                                title={firstBlog.attributes[FIELDS_BLOG[FILED_KEY_TITLE]]}
                                description={
                                    firstBlog.attributes[FIELDS_BLOG[FIELD_KEY_DESCRIPTION]]
                                }
                                goTo={`${BLOG_POST_ROUTER.path}/${firstBlog.id}`}
                                date={firstBlog.attributes[FIELDS_BLOG[FIELD_KEY_CREATED]]}
                                button='Leer más'
                            />

                            <ListPost list={listBlog} />

                            <Pagination
                                page={page}
                                countPage={totalPage}
                                onChangePage={handleChangePage}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Blog
