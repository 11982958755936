export const formatPhoneNumber = ({ phoneNumber }) => {
    if (!phoneNumber) return

    // separamos el numero
    const split = phoneNumber.split('')

    // agregamos los espacios
    split.splice(8, 0, ' ')
    split.splice(5, 0, ' ')
    split.splice(2, 0, ' ')

    // juntamos y retornamos
    return split.join('')
}

export const scrollTop = () => {
    window.scrollTo(0, 0)
}
