import React from 'react'
import ContentLoader from 'react-content-loader'

const LoadingFilter = () => (
    <ContentLoader
        speed={2}
        viewBox='0 0 400 400'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
    >
        <rect x='35' y='10' rx='5' ry='5' width='360' height='10' />
        <rect x='35' y='45' rx='5' ry='5' width='360' height='10' />
        <rect x='35' y='80' rx='5' ry='5' width='360' height='10' />
        <rect x='35' y='115' rx='5' ry='5' width='360' height='10' />
        <rect x='3' y='5' rx='4' ry='4' width='20' height='20' />
        <rect x='3' y='40' rx='4' ry='4' width='20' height='20' />
        <rect x='3' y='75' rx='4' ry='4' width='20' height='20' />
        <rect x='3' y='110' rx='4' ry='4' width='20' height='20' />

        <rect x='35' y='210' rx='5' ry='5' width='360' height='10' />
        <rect x='35' y='245' rx='5' ry='5' width='360' height='10' />
        <rect x='35' y='280' rx='5' ry='5' width='360' height='10' />
        <rect x='35' y='315' rx='5' ry='5' width='360' height='10' />
        <rect x='3' y='205' rx='4' ry='4' width='20' height='20' />
        <rect x='3' y='240' rx='4' ry='4' width='20' height='20' />
        <rect x='3' y='275' rx='4' ry='4' width='20' height='20' />
        <rect x='3' y='310' rx='4' ry='4' width='20' height='20' />
    </ContentLoader>
)

export default LoadingFilter
