import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Input, InputGroupRange } from '../Input'
import { SelectCustom } from '../SelectCustom'
import { LocationIcon, TypePropertieIcon } from '../Svg'

// Utils
import {
    FIELD_KEY_LOCATION,
    FIELD_KEY_TYPE,
    FIELD_KEY_PRICE,
} from '../../utils/fields'

// Const
import { LIST_TYPE_PROPERTY_SELECT } from '../../const/selectValues'
import { MIN_PRICE, MAX_PRICE } from '../../const/inputRange'

const InputsFilter = ({
    data,
    onChangeFilter,
    optionByMunicipality,
    hiddenType,
    addInputCode,
}) => {
    const handleChangeFilter = ({ value, field }) => {
        onChangeFilter({ field, value })
    }
    return (
        <div className='inputsFilter_properties'>
            <Input label='Municipio' labelBold>
                <SelectCustom
                    onChange={(e) =>
                        handleChangeFilter({ field: FIELD_KEY_LOCATION, value: e })
                    }
                    placeholder='Municipio'
                    listOptions={optionByMunicipality || []}
                    className='hero_img_select zIndex-6'
                    icon={<LocationIcon />}
                    isMulti
                    defaultValue={data[FIELD_KEY_LOCATION]}
                />
            </Input>

            {!hiddenType && (
                <Input label='Tipo de propiedad' labelBold>
                    <SelectCustom
                        onChange={(e) =>
                            handleChangeFilter({ field: FIELD_KEY_TYPE, value: e })
                        }
                        placeholder='Tipo de propiedad'
                        listOptions={LIST_TYPE_PROPERTY_SELECT}
                        className='hero_img_select zIndex-5'
                        icon={<TypePropertieIcon />}
                        isMulti
                        defaultValue={data[FIELD_KEY_TYPE]}
                    />
                </Input>
            )}

            <InputGroupRange
                label='Presupuesto'
                field={FIELD_KEY_PRICE}
                type={FIELD_KEY_PRICE}
                min={MIN_PRICE}
                max={MAX_PRICE}
                data={data}
                onChange={handleChangeFilter}
            />

            {addInputCode?.name && (
                <Input
                    label={addInputCode.label}
                    labelBold
                    placeholder={addInputCode.label}
                    name={addInputCode}
                    value={data[addInputCode.name] || ''}
                    onChange={(e) =>
                        handleChangeFilter({
                            value: e.target.value,
                            field: addInputCode.name,
                        })
                    }
                />
            )}
        </div>
    )
}

InputsFilter.propTypes = {
    data: PropTypes.object,
    onChangeFilter: PropTypes.func,
    optionByMunicipality: PropTypes.array,
    hiddenType: PropTypes.bool,
    addInputCode: PropTypes.bool,
}

export default InputsFilter