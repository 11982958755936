import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const NavigateCustom = ({ activeClass, url, text, customClass = '' }) => {
    return (
        <NavLink
            to={url}
            className={({ isActive }) =>
                isActive ? `${activeClass} ${customClass}` : customClass
            }
        >
            {text}
        </NavLink>
    )
}

NavigateCustom.propTypes = {
    activeClass: PropTypes.string,
    url: PropTypes.string,
    text: PropTypes.string,
    customClass: PropTypes.string,
}

export default NavigateCustom
