import React, { useEffect } from 'react'

// Components
import { BlogHero } from '../Blog/components/BlogHero'
import { PropertyContact } from '../Property/Components/PropertyContact'
import { Title, TitleIcon } from '../../components/Title'
import { Text } from '../../components/Text'

// Icons
import { 
    StudyIcon, 
    TopograficoIcon, 
    StructuralDesignIcon, 
    PlanIcon, 
    ArchitecturalDesignIcon,
} from '../../components/Svg'

// Routes
import { HOME_ROUTER } from '../../helpers/constants/routes'

// Image
import ImageHero from '../../assets/images/blog.png'
import HeaderConstruction from '../../assets/images/headerConstruction.png'
import ServiceConstruction from '../../assets/images/serviceConstruction.png'
import GaleryConstruction1 from '../../assets/images/galeryConstruction1.png'
import GaleryConstruction2 from '../../assets/images/galeryConstruction2.png'

// Helpers
import { scrollTop } from '../../helpers/utils'

const DesignAndConstruction = () => {

    useEffect(() => {
        scrollTop()
    }, [])

    return (
        <div className='designAndConstruction'>
            <BlogHero
                image={HeaderConstruction}
                title='Diseño y construcción'
                description='Nuestro equipo de arqutectos Desarrollan proyectos constructivos según los gustos y necesidades.'
                goBack={HOME_ROUTER.path}
            />
            <div className="container">
                <div className="content">
                    <div className='property_info designAndConstruction_content'>
                        <div className='property_item'>
                            <Title size='sm'>Diseño arquitectónico, estructural y redes tecnicas</Title>
                            <Text>Tierras construcción tiene la capacidad creativa y técnica de desarrollar proyectos constructivos bajo los gustos, necesidades y capacidad económica de nuestros clientes, usando los diversos tipos de construcciones.</Text>

                            <div className="designAndConstruction_img">
                                <img src={ImageHero} alt="Diseño y Construcción" />
                            </div>

                            <Title size='sm'>Etapas de diseño</Title>
                            <div className="designAndConstruction_steps">
                                <div className="designAndConstruction_step">
                                    <Title size='lg'>1</Title>
                                    <Text size='sm' color='gray'>IDEA BÁSICA</Text>
                                </div>
                                <div className="designAndConstruction_step">
                                    <Title size='lg'>2</Title>
                                    <Text size='sm' color='gray'>ANTEPROYECTO</Text>
                                </div>
                                <div className="designAndConstruction_step">
                                    <Title size='lg'>3</Title>
                                    <Text size='sm' color='gray'>PROYECTO</Text>
                                </div>
                                <div className="designAndConstruction_step">
                                    <Title size='lg'>4</Title>
                                    <Text size='sm' color='gray'>ENTREGA</Text>
                                </div>
                            </div>

                            <Title size='sm'>Servicios</Title>
                            <div className="designAndConstruction_img">
                                <img src={ServiceConstruction} alt="Diseño y Construcción" />
                            </div>

                            <div className="designAndConstruction_features">
                                <TitleIcon titleSize='xs' sizeIcon='xl' icon={<StudyIcon />} title='Estudio de suelos' />
                                <TitleIcon titleSize='xs' sizeIcon='xl' icon={<TopograficoIcon />} title='Levantamiento topográfico' />
                                <TitleIcon titleSize='xs' sizeIcon='xl' icon={<ArchitecturalDesignIcon />} title='Diseño arquitectónico' />
                                <TitleIcon titleSize='xs' sizeIcon='xl' icon={<StructuralDesignIcon />} title='Diseño estructural' />
                                <TitleIcon titleSize='xs' sizeIcon='xl' icon={<PlanIcon />} title='Plan de manejo ambiental' />
                            </div>

                            <div className="designAndConstruction_images">
                                <img src={GaleryConstruction1} alt="Diseño y Construcción" />
                                <img src={GaleryConstruction2} alt="Diseño y Construcción" />
                            </div>
                            <div className="designAndConstruction_img">
                                <img src={HeaderConstruction} alt="Diseño y Construcción" />
                            </div>
                            <div className="designAndConstruction_video">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/wtR3GEwZHEQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='property_item'>
                            <PropertyContact />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesignAndConstruction
