import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Text } from '../Text'
import { ButtonMoreAndLess } from '../Button'

const SelectCount = ({ text, number, onClick }) => {
    return (
        <div className='selectCount'>
            <Text>{text}</Text>
            <ButtonMoreAndLess number={Number(number)} onClick={onClick} />
        </div>
    )
}

SelectCount.propTypes = {
    text: PropTypes.string,
    number: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onClick: PropTypes.func,
}

export default SelectCount
