import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useSearchParams } from 'react-router-dom'

// Components
import { Title } from '../../components/Title'
import { Button } from '../../components/Button'
import { Pagination } from '../../components/Pagination'
import { LoadingCardProperty, LoadingFilter } from '../../components/Loading'
import { OrderBy } from '../../components/OrderBy'
import { InputsFilter } from '../../components/InputsFilter'
import { LotList } from '../../components/LotList'
import { FilterMobile } from '../../components/Filter'

// Hook
import { useOpenAndCloseHook, useMunicipality, useLotes } from '../../hooks'

// Icons
import { ArrowLeftIcon, FilterIcon } from '../../components/Svg'

// Fields
import {
    FIELD_KEY_LOCATION,
    FIELD_KEY_PRICE,
    FIELD_ORDER_BY,
    FIELD_LOTES,
} from '../../utils/fields'

// Const
import { MIN_PRICE } from '../../const/inputRange'

// Helpers
import { scrollTop } from '../../helpers/utils'

const TYPE_ONLY_VALID = 'only'

const Lotes = () => {
    const [params] = useSearchParams()

    const {
        listLotes,
        page,
        totalPage,
        loadingLotes,
        dataFilterLotes,
        getLotes,
        handleChangePageLote,
        filterLotes,
        defaultFilter,
    } = useLotes()
    const { getDataMunicipality, getOptionForSelect } = useMunicipality()

    const [loadingPage, setLoadingPage] = useState(true)
    const [modalFilterTablet, setModalFilterTablet] = useOpenAndCloseHook()
    const isLaptopOrMobile = useMediaQuery({
        query: '(max-width: 1300px)',
    })

    const validUriParams = ({ valid = 'all' } = {}) => {
        if (valid === TYPE_ONLY_VALID)
            return params.get(FIELD_KEY_LOCATION) || params.get(FIELD_KEY_PRICE)

        return !params.get(FIELD_KEY_LOCATION) && !params.get(FIELD_KEY_PRICE)
    }

    const createUriFilterDefault = () => {
        if (validUriParams()) return ''

        const uriFilter = {}
        if (params.get(FIELD_KEY_LOCATION))
            uriFilter[FIELD_KEY_LOCATION] = [
                {
                    label: params.get(FIELD_KEY_LOCATION),
                    value: params.get(FIELD_KEY_LOCATION),
                },
            ]

        if (params.get(FIELD_KEY_PRICE))
            uriFilter[FIELD_KEY_PRICE] = {
                min: MIN_PRICE,
                max:
                    Number(params.get(FIELD_KEY_PRICE)) >= MIN_PRICE
                        ? Number(params.get(FIELD_KEY_PRICE))
                        : MIN_PRICE,
            }

        return defaultFilter({
            data: uriFilter,
        })
    }

    useEffect(() => {
        (async () => {
            try {
                scrollTop()
                const filter = await createUriFilterDefault()
                await Promise.all([getLotes({ filter }), getDataMunicipality()])
            } catch (error) {
                console.error(error)
            } finally {
                setLoadingPage(false)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFilterProperties = ({ field, value }) => {
        filterLotes({ field, value })
    }

    return (
        <div className='container'>
            <div className='content propertiesPage'>
                <div className='propertiesPage_filter'>
                    <div className='propertiesPage_filter_title'>
                        <ArrowLeftIcon />
                        <Title size='lg'>Lotes</Title>
                    </div>

                    {!loadingPage ? (
                        <>
                            {console.log({ dataFilterLotes })}
                            <InputsFilter
                                data={dataFilterLotes}
                                onChangeFilter={handleFilterProperties}
                                optionByMunicipality={getOptionForSelect()}
                                hiddenType
                                addInputCode={{
                                    name: FIELD_LOTES.code,
                                    label: 'Código de lote',
                                }}
                            />
                        </>
                    ) : (
                        <LoadingFilter />
                    )}
                </div>
                <div className='propertiesPage_content'>
                    <div className='propertiesPage_content_header'>
                        <div className='propertiesPage_filter_title propertiesPage_title_mobile'>
                            <ArrowLeftIcon />
                            <Title size='md'>Lotes</Title>
                        </div>

                        <div className='propertiesPage_content_header_btn'>
                            {isLaptopOrMobile && (
                                <Button
                                    color='secundary'
                                    rounded
                                    transparent
                                    textColor='primary'
                                    icon
                                    className='propertiesPage_content_filter'
                                    onClick={setModalFilterTablet}
                                >
                                    <FilterIcon />
                                </Button>
                            )}

                            <OrderBy
                                mobile={isLaptopOrMobile}
                                orderBy={dataFilterLotes[FIELD_ORDER_BY]}
                                onChangeFilter={handleFilterProperties}
                            />
                        </div>
                    </div>
                    <div className='propertiesPage_content_list'>
                        {loadingPage || loadingLotes ? (
                            <LoadingCardProperty count={4} />
                        ) : (
                            <>
                                {listLotes.data.length > 0 && (
                                    <>
                                        <LotList data={listLotes.data} />
                                        <Pagination
                                            className='propertiesPage_pagination'
                                            page={Number(page)}
                                            countPage={Number(totalPage)}
                                            onChangePage={handleChangePageLote}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {isLaptopOrMobile && (
                <>
                    {modalFilterTablet && (
                        <FilterMobile close={setModalFilterTablet} mobileHiddenOverFlow>
                            <InputsFilter
                                data={dataFilterLotes}
                                onChangeFilter={handleFilterProperties}
                                optionByMunicipality={getOptionForSelect()}
                                hiddenType
                            />
                        </FilterMobile>
                    )}
                </>
            )}
        </div>
    )
}

export default Lotes