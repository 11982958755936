import React from 'react'
import PropTypes from 'prop-types'

// Components
import { FeaturedPropertiesLargeList } from '../../../../components/FeaturedPropertiesLargeList'

const ListFavorite = ({ data }) => {
    const items = data.map(i => ({ ...i, ...i.details }))
    return (
        <div className='listFavorite'>
            <FeaturedPropertiesLargeList data={items} shadow />
        </div>
    )
}

ListFavorite.propTypes = {
    data: PropTypes.array,
}

export default ListFavorite
