import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Components
import { Text } from '../../components/Text'
import { PropertyGalery } from './Components/PropertyGalery'
import { BasicInfo } from './Components/BasicInfo'
import { ListFeatured } from './Components/ListFeatured'
import { PlacesOfInterest } from './Components/PlacesOfInterest'
import { PropertyContact } from './Components/PropertyContact'
import { PropertyHeader } from './Components/PropertyHeader'
import { SliderGalery } from './Components/SliderGalery'
import { LoadingDetailProperty } from '../../components/Loading'
import { RenderMarkDown } from '../../components/RenderMarkDown'
import { Maps } from '../../components/Maps'

// Hooks
import { useOpenAndCloseHook, useProperties } from '../../hooks'

// Utils
import { FIELDS_PROPERTY } from '../../utils/fields'
import { createUrlShare } from '../../utils/helpers'

// Helpers
import { scrollTop } from '../../helpers/utils'

const Property = () => {
    const { id } = useParams()
    const [loadingPage, setLoadingPage] = useState(true)
    const [openGalery, setOpenGalery] = useOpenAndCloseHook()
    const { propertyInfo, getProperty } = useProperties()

    useEffect(() => {
        (async () => {
            try {
                scrollTop()
                await getProperty({ id })
            } catch (error) {
                console.error(error)
            } finally {
                setLoadingPage(false)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='container'>
            <div className='content property'>
                {loadingPage ? (
                    <LoadingDetailProperty />
                ) : (
                    <>
                        <Helmet>
                            <meta charSet='utf-8' />
                            <title>TierrasGP | {propertyInfo.attributes[FIELDS_PROPERTY.name]}</title>
                            <link rel='canonical' href={createUrlShare({ decode: false })} />

                            <meta name="title" content={`TierrasGP | ${propertyInfo.attributes[FIELDS_PROPERTY.name]}`} />

                            <meta property="og:type" content="website" />
                            <meta property="og:url" content={createUrlShare({ decode: false })} />
                            <meta property="og:title" content={`TierrasGP | ${propertyInfo.attributes[FIELDS_PROPERTY.name]}`} />
                            <meta property="og:image" content={propertyInfo.attributes[FIELDS_PROPERTY.imgPrimary].data.attributes.url} />

                            <meta property="twitter:card" content="summary_large_image" />
                            <meta property="twitter:url" content={createUrlShare({ decode: false })} />
                            <meta property="twitter:title" content={`TierrasGP | ${propertyInfo.attributes[FIELDS_PROPERTY.name]}`} />
                            <meta property="twitter:image" content={propertyInfo.attributes[FIELDS_PROPERTY.imgPrimary].data.attributes.url}></meta>
                        </Helmet>
                        <PropertyHeader
                            location={
                                propertyInfo.attributes[FIELDS_PROPERTY.location].data
                                    .attributes.Nombre
                            }
                            price={propertyInfo.attributes.Valor}
                            name={propertyInfo.attributes[FIELDS_PROPERTY.name]}
                            code={propertyInfo.attributes[FIELDS_PROPERTY.propertyCode]}
                        />
                        <PropertyGalery
                            imgPrimary={propertyInfo.attributes[FIELDS_PROPERTY.imgPrimary]}
                            galery={propertyInfo.attributes[FIELDS_PROPERTY.galery]}
                            onOpenGalery={setOpenGalery}
                        />

                        <div className='property_info'>
                            <div className='property_item'>
                                <BasicInfo details={propertyInfo.attributes} />

                                <ListFeatured data={propertyInfo.attributes} />

                                <div className='property_description bg_grey'>
                                    <Text bold>Descripción de la propiedad</Text>
                                    <RenderMarkDown>
                                        {propertyInfo.attributes[FIELDS_PROPERTY.description]}
                                    </RenderMarkDown>
                                </div>

                                {propertyInfo?.attributes[FIELDS_PROPERTY.embeddedVideo] && (
                                    <div
                                        className='property_video'
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                propertyInfo?.attributes[FIELDS_PROPERTY.embeddedVideo],
                                        }}
                                    ></div>
                                )}

                                {propertyInfo.attributes[FIELDS_PROPERTY.lat] &&
                                    propertyInfo.attributes[FIELDS_PROPERTY.lng] && (
                                    <div className='property_map'>
                                        <Maps
                                            lat={propertyInfo.attributes[FIELDS_PROPERTY.lat]}
                                            lng={propertyInfo.attributes[FIELDS_PROPERTY.lng]}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className='property_item'>
                                <PropertyContact />
                            </div>
                        </div>

                        {propertyInfo?.attributes[FIELDS_PROPERTY.placesOfInterest]?.data
                            .length > 0 && (
                            <PlacesOfInterest
                                listPlaces={
                                    propertyInfo.attributes[FIELDS_PROPERTY.placesOfInterest].data
                                }
                            />
                        )}
                    </>
                )}
            </div>

            {openGalery && (
                <SliderGalery
                    galery={[
                        propertyInfo.attributes[FIELDS_PROPERTY.imgPrimary].data,
                        ...propertyInfo.attributes[FIELDS_PROPERTY.galery].data,
                    ]}
                    close={setOpenGalery}
                />
            )}
        </div>
    )
}

export default Property
