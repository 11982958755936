import { useState } from 'react'
import { API_PUBLIC } from '../service/api'

const ENDPOINT = '/politica-privacidad'

const usePrivacyPolicy = () => {
    const [dataPrivacyPolicy, setDataPrivacyPolicy] = useState([])
    const [errorDataPrivacyPolicy, setErrorDataPrivacyPolicy] = useState('')
    const [loadingDataPrivacyPolicy, setLoadingDataPrivacyPolicy] = useState(true)

    const getDataPrivacyPolicy = async () => {
        try {
            setLoadingDataPrivacyPolicy(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}`,
            })

            setDataPrivacyPolicy(res.data.attributes)
        } catch (error) {
            setErrorDataPrivacyPolicy(error)
        } finally {
            setLoadingDataPrivacyPolicy(false)
        }
    }

    return {
        dataPrivacyPolicy,
        errorDataPrivacyPolicy,
        loadingDataPrivacyPolicy,
        getDataPrivacyPolicy,
    }
}

export default usePrivacyPolicy
