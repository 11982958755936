import React, { useEffect } from 'react'

// Components
import { RenderPolicy } from '../components/RenderPolicy'

// Hooks
import { useTermsAndConditions } from '../../../hooks'

// Helpers
import { scrollTop } from '../../../helpers/utils'

const TermsAndConditions = () => {
    const {
        dataTermsAndConditions,
        loadingDataTermsAndConditions,
        getDataTermsAndConditions,
    } = useTermsAndConditions()

    useEffect(() => {
        (async () => {
            scrollTop()
            await getDataTermsAndConditions()
        })()
    }, [])

    return (
        <RenderPolicy
            title={dataTermsAndConditions.Titulo}
            content={dataTermsAndConditions.Contenido}
            loading={loadingDataTermsAndConditions}
        />
    )
}

export default TermsAndConditions
