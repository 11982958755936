import { FIELDS_PROPERTY, FIELD_PARCELLING, FIELD_LOTES } from './fields'

export const GENERAL_IGNORER = ['createdAt', 'updatedAt', 'publishedAt']

export const IGNORER_BASIC_INFO = [
    FIELDS_PROPERTY.name,
    FIELDS_PROPERTY.description,
    FIELDS_PROPERTY.embeddedVideo,
    FIELDS_PROPERTY.contactPhone,
    FIELDS_PROPERTY.imgPrimary,
    FIELDS_PROPERTY.features,
    FIELDS_PROPERTY.galery,
    FIELDS_PROPERTY.placesOfInterest,
    FIELDS_PROPERTY.featuredProperty,
    FIELDS_PROPERTY.lat,
    FIELDS_PROPERTY.lng,
    FIELDS_PROPERTY.location,
    ...GENERAL_IGNORER,
]

export const IGNORER_BASIC_INFO_PARCELLING = [
    FIELD_PARCELLING.name,
    FIELD_PARCELLING.outstanding,
    FIELD_PARCELLING.description,
    FIELD_PARCELLING.imgPrimary,
    FIELD_PARCELLING.lotes,
    FIELD_PARCELLING.imgHeader,
    FIELD_PARCELLING.lat,
    FIELD_PARCELLING.lng,
    FIELD_PARCELLING.embeddedVideo,
    FIELD_PARCELLING.galery,
    FIELD_PARCELLING.rangePrice,
    FIELD_PARCELLING.basicInformation,
    FIELD_PARCELLING.contact,
    FIELD_PARCELLING.price,
    ...GENERAL_IGNORER,
]

export const IFNORER_BASIC_INFO_LOTE = [
    FIELD_LOTES.type,
    FIELD_LOTES.via_access,
    FIELD_LOTES.galery,
    FIELD_LOTES.imgPrimary,
    FIELD_LOTES.features,
    FIELD_LOTES.description,
    FIELD_LOTES.embeddedVideo,
    FIELD_LOTES.contact_phone,
    FIELD_LOTES.price,
    FIELD_LOTES.outstanding,
    FIELD_LOTES.placesOfInterest,
    FIELD_LOTES.predial,
    FIELD_LOTES.name,
    FIELD_LOTES.lat,
    FIELD_LOTES.lng,
    FIELD_LOTES.contact,
    FIELD_LOTES.basicInformation,
    ...GENERAL_IGNORER,
]