import React from 'react'
import PropTypes from 'prop-types'

// Components
import { SelectCustom } from '../SelectCustom'

// Icons
import { OrderIcon } from '../Svg'

// List Options
import { LIST_ORDER_BY } from '../../const/selectValues'

// Fields
import { FIELD_ORDER_BY } from '../../utils/fields'

const OrderBy = ({ mobile, orderBy, onChangeFilter }) => {
    return (
        <>
            {mobile ? (
                <SelectCustom
                    onChange={(e) =>
                        onChangeFilter({
                            field: FIELD_ORDER_BY,
                            value: e,
                        })
                    }
                    value={orderBy}
                    icon={<OrderIcon />}
                    placeholder='Ordenar por'
                    className='orderBy_select'
                    listOptions={LIST_ORDER_BY}
                />
            ) : (
                <SelectCustom
                    onChange={(e) =>
                        onChangeFilter({
                            field: FIELD_ORDER_BY,
                            value: e,
                        })
                    }
                    value={orderBy}
                    icon={<OrderIcon />}
                    placeholder='Ordenar por'
                    className='orderBy_select'
                    listOptions={LIST_ORDER_BY}
                />
            )}
        </>
    )
}

OrderBy.propTypes = { 
    mobile: PropTypes.bool,
    orderBy: PropTypes.object, 
    onChangeFilter: PropTypes.func,
}

export default OrderBy
