import * as React from 'react'
import PropTypes from 'prop-types'

const YoutubeIcon = ({ color = '#69A831' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 70'>
        <g transform='translate(-.167 -.001)'>
            <g
                fill='none'
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={1.5}
            >
                <path d='M44.389 46.992a7.034 7.034 0 0 0 7.284-6.755V30.205a7.034 7.034 0 0 0-7.284-6.759H25.327a7.034 7.034 0 0 0-7.284 6.759v10.032a7.034 7.034 0 0 0 7.284 6.755Z' />
                <path d='m34.031 30.474 5.315 3.612a1.122 1.122 0 0 1 0 1.937l-5.315 3.951c-1.009.682-2.536.1-2.536-.971v-7.567c0-1.062 1.528-1.649 2.536-.962Z' />
            </g>
        </g>
    </svg>
)

YoutubeIcon.propTypes = {
    color: PropTypes.string,
}

export default YoutubeIcon
