import React, { useState, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

// Const
import { USER_BUY, USER_SELL } from '../../const/typeUser'

import { useOpenAndCloseHook } from '../../hooks'

import { DASHBOARD_PROPERTIES_ROUTER } from '../../helpers/constants/routes'
import { API_PRIVATE } from '../../service/api'

// Hooks
import { useMunicipality, useFeatured } from '../../hooks'

// Components
import ModifiedUser from './Components/ModifiedUser/ModifiedUser'
import { Title } from '../../components/Title'
import { Text } from '../../components/Text'
import { Button } from '../../components/Button'
import { CheckBox } from '../../components/Input'
import Context from '../../Context/UserContext'
import { NotificationIcon } from '../../components/Svg'
import { InputsFilter } from '../../components/InputsFilter'
import { Filter } from '../../components/Filter'
import { ModalTypeUser, ModalPreferences } from '../../components/Modal'
import ModalRegister from '../Auth/components/ModalRegister/ModalRegister'

const DashboardFavorite = () => {
    const { user, setUser } = useContext(Context)

    const { getDataMunicipality, getOptionForSelect } = useMunicipality()
    const [notificationType, setNotificationType] = useState([])
    const [loading, setLoading] = useState(false)
    const [preferences, setPreferences] = useState(null)
    //const [mapListFeatures, setMapListFeatures] = useState([])
    const [params] = useSearchParams()
    const [modalTypeUser, setModalTypeUser] = React.useState(false)
    const [typeUser, setTypeUser] = React.useState(USER_BUY)
    const [modalRegisterProperty, setModalRegisterProperty] = useOpenAndCloseHook()
    const [loadingRegister, setLoadingRegister] = React.useState(false)
    const [modalPreferences, setModalPreferences] = useOpenAndCloseHook()
    const navigate = useNavigate()

    const { listFeatures, getFeatures } = useFeatured()

    const handleFilterProperties = ({ field, value }) => {
        setPreferences({ ...preferences, [field]: value })
    }

    const changeNotificationType = (ev, type) => {
        const nt = ev.target.checked ? [...notificationType, type] : notificationType.filter(item => item !== type)
        setNotificationType(nt)
        setPreferences({ ...preferences, notificationType: nt })
    }

    const savePreferences = async (ev = null, data) => {
        if (ev) ev.preventDefault()
        const body = data || preferences
        const pres = JSON.parse(JSON.stringify(body))
        delete pres.notificationType

        const payload = {
            notificationType: notificationType,
            propertiesInterested: pres,
        }

        if (loading) return
        setLoading(true)
        await API_PRIVATE({
            endpoint: 'me/preferences',
            method: 'PUT',
            body: payload,
        })
        setUser({ ...user, preferences: payload })
        toast.success('Preferencias guardadas correctamente')
        setLoading(false)
    }

    const validUriParams = () => {
        if (params.get('v') && params.get('v') === 'tour') {
            setModalTypeUser(true)
        }
    }

    const handleChangeTypeUser = ({ type }) => setTypeUser(type)

    const handleSelectTypeUser = () => {
        if (typeUser === USER_SELL) {
            setModalRegisterProperty(true)
        } else {
            setModalPreferences(true)
        }
    }

    const handleRegisterProperty = async (data) => {
        setLoadingRegister(true)
        try {
            API_PRIVATE({
                endpoint: 'save-property',
                method: 'POST',
                body: { ...data.data },
            })
                .then(() => {
                    navigate(DASHBOARD_PROPERTIES_ROUTER.path)
                    setModalRegisterProperty(false)
                    setModalTypeUser(false)
                    toast.success('Propiedad guardada correctamente')
                })
                .catch(err => {
                    console.log(err)
                    toast.error('Ha ocurrido un error al guardar la propiedad, intenta más tarde')
                })
                .finally(() => setLoadingRegister(false))
        } catch (error) {
            setLoadingRegister(false)
            toast.error('Ha ocurrido un error al guardar la propiedad, intenta más tarde')
        }
    }

    React.useEffect(() => {
        (async () => {
            try {
                validUriParams()
                await Promise.all([getFeatures(), getDataMunicipality()])
            } catch (error) {
                console.error(error)
            }
        })()
    }, [])

    React.useEffect(() => {
        const pres = user?.preferences
        if (pres) {
            setPreferences(pres?.propertiesInterested || {})
            setNotificationType(pres?.notificationType || [])
        }
    }, [user])

    /*
    React.useEffect(() => {
        if (listFeatures?.data) {
            const map = listFeatures.data.map(item => {
                return item
            })
            setMapListFeatures(map)
        }
    }, [listFeatures])
    */

    return (
        <>
            <div className='dashboardPage'>
                {user?.name && <Title size='lg'>Hola { user?.name }</Title> }
                <div>
                    <Title customClass='mb-8' size='sm'>Gestione su información personal</Title>
                    <Text color='gray'>
                        Esta es una cuenta de TierrasGP. Edite su información personal y ajuste las notificaciones que
                        desea recibir de TierrasGP.
                    </Text>
                </div>

                <Title size='sm'>Acerca de</Title>
                { user && <ModifiedUser user={user} setUser={setUser} /> }

                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='header_navidate_profile' style={{ marginRight: '16px' }}>
                            <NotificationIcon />
                        </div>
                        <Title size='sm'>
                            Notificaciones
                        </Title>
                    </div>
                    <div style={{ margin: '16px 0' }}>
                        <Text color='gray'>
                            Selecciona el tipo de notificaciones que desea recibir de TierrasGP.
                        </Text>
                    </div>
                    <div>
                        <CheckBox
                            label='Recibir notificaciones via whatsapp'
                            name='notifications'
                            value={notificationType.includes('phone')}
                            onChange={(ev) => changeNotificationType(ev, 'phone')}
                        />
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <CheckBox
                            label='Recibir notificaciones via correo electrónico'
                            name='notifications2'
                            value={notificationType.includes('email')}
                            onChange={(ev) => changeNotificationType(ev, 'email')}
                        />
                    </div>
                    <div style={{ paddingTop: '20px' }}>
                        <Button
                            rounded
                            alignSelfCenter
                            onClick={(ev) => savePreferences(ev, 'notifications')}
                            pending={loading === 'notifications'}
                        >
                            Guardar notificaciones
                        </Button>
                    </div>
                </div>

                <hr />

                {/* Filters */}
                <div className="container-filters">

                    <div style={{ marginBottom: 16 }}>
                        <Text color='gray'>
                            Guarde las preferencias adecuadas para que pueda recibir notificaciones más acertadas.
                        </Text>
                    </div>

                    {preferences && handleFilterProperties && <>
                        <div style={{ paddingBlock: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                rounded
                                alignSelfCenter
                                onClick={(ev) => savePreferences(ev, 'preferences')}
                                pending={loading === 'preferences'}
                            >
                                Guardar preferencias
                            </Button>
                        </div>

                        <InputsFilter
                            data={preferences}
                            onChangeFilter={handleFilterProperties}
                            optionByMunicipality={getOptionForSelect()}
                        />

                        <Filter
                            data={preferences}
                            onChangeFilter={handleFilterProperties}
                            hideOptions={['propertie_code']}
                            filterFeatureList={listFeatures.data}
                        />

                        <div style={{ paddingBlock: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                rounded
                                alignSelfCenter
                                onClick={(ev) => savePreferences(ev, 'preferences')}
                                pending={loading === 'preferences'}
                            >
                                Guardar preferencias
                            </Button>
                        </div>
                    </>}
                </div>
            </div>

            {modalTypeUser && (
                <ModalTypeUser
                    selectType={typeUser}
                    onConfirm={handleSelectTypeUser}
                    onSelectType={handleChangeTypeUser}
                    onClose={() => setModalTypeUser(false)}
                />
            )}

            {modalRegisterProperty && (
                <ModalRegister
                    step={typeUser}
                    close={() => setModalRegisterProperty(false)}
                    loading={loadingRegister}
                    onRegister={handleRegisterProperty}
                />
            )}

            {modalPreferences && (
                <ModalPreferences onClose={setModalPreferences} />
            )}
        </>
    )
}

export default DashboardFavorite
