import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Text } from '../Text'
import { FacebookShare, LinkShare, WhatsAppShare } from '.'

const SocialShare = ({ text, link, face, whats, color = 'white' }) => {
    return (
        <div className='propertyHeader_share'>
            <Text color={color}>{text}</Text>
            {link && <LinkShare />}
            {face && <FacebookShare />}
            {whats && <WhatsAppShare />}
        </div>
    )
}

SocialShare.propTypes = {
    text: PropTypes.string,
    link: PropTypes.bool,
    face: PropTypes.bool,
    whats: PropTypes.bool,
    color: PropTypes.string,
}

export default SocialShare
