import React from 'react'
import ContentLoader from 'react-content-loader'

// Components
import { LoadingContainer } from '.'

const LoadingPost = () => {
    return (
        <>
            <ContentLoader viewBox='0 0 700 150'>
                <rect x='0' y='0' rx='5' ry='5' width='100%' height='150' />
            </ContentLoader>
            <LoadingContainer>
                <ContentLoader viewBox='0 0 700 620'>
                    <rect x='0' y='42' rx='5' ry='5' width='100%' height='200' />
                    <rect x='0' y='265' rx='5' ry='5' width='100%' height='30' />
                    <rect x='0' y='335' rx='5' ry='5' width='65%' height='10' />
                    <rect x='75%' y='315' rx='5' ry='5' width='30%' height='10' />
                    <rect x='0' y='315' rx='5' ry='5' width='65%' height='10' />
                    <rect x='75%' y='315' rx='5' ry='5' width='30%' height='10' />
                    <rect x='0' y='335' rx='5' ry='5' width='65%' height='10' />
                    <rect x='75%' y='335' rx='5' ry='5' width='30%' height='10' />
                    <rect x='0' y='355' rx='5' ry='5' width='65%' height='8' />
                    <rect x='75%' y='355' rx='5' ry='5' width='30%' height='8' />
                    <rect x='0' y='395' rx='5' ry='5' width='65%' height='8' />
                    <rect x='75%' y='395' rx='5' ry='5' width='30%' height='8' />
                    <rect x='0' y='415' rx='5' ry='5' width='65%' height='8' />
                    <rect x='75%' y='415' rx='5' ry='5' width='30%' height='8' />
                    <rect x='0' y='435' rx='5' ry='5' width='65%' height='8' />
                    <rect x='75%' y='435' rx='5' ry='5' width='30%' height='8' />
                    <rect x='0' y='455' rx='5' ry='5' width='65%' height='8' />
                    <rect x='75%' y='455' rx='5' ry='5' width='30%' height='8' />
                    <rect x='0' y='485' rx='5' ry='5' width='65%' height='8' />
                    <rect x='75%' y='485' rx='5' ry='5' width='30%' height='8' />
                    <rect x='0' y='505' rx='5' ry='5' width='65%' height='8' />
                    <rect x='75%' y='505' rx='5' ry='5' width='30%' height='8' />
                    <circle cx='87%' cy='560' r='18' />
                    <circle cx='94%' cy='560' r='18' />
                </ContentLoader>
            </LoadingContainer>
        </>
    )
}

export default LoadingPost
