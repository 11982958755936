import * as React from 'react'
import PropTypes from 'prop-types'

const FacebookShareIcon = ({ color = '#69A831' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 30'>
        <g transform='translate(.479)'>
            <rect
                width={31}
                height={30}
                rx={6}
                transform='translate(-.479)'
                fill={color}
            />
            <path fill='none' d='M6.522 7h17v17h-17z' />
            <path
                d='M22.391 15.455a7.025 7.025 0 1 0-8.43 6.885v-4.777h-1.405v-2.108h1.405v-1.756a2.462 2.462 0 0 1 2.459-2.459h1.756v2.108h-1.405a.7.7 0 0 0-.7.7v1.407h2.108v2.108h-2.11v4.883a7.024 7.024 0 0 0 6.322-6.991Z'
                fill='#fff'
            />
        </g>
    </svg>
)

FacebookShareIcon.propTypes = {
    color: PropTypes.string,
}

export default FacebookShareIcon
