import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../../components/Title'
import { Button } from '../../../../../components/Button'
import { Text } from '../../../../../components/Text'
import { Maps } from '../../../../../components/Maps'

const StepTwo = ({ loading, data, onSubmit, goBack }) => (
    <>
        <Title size='sm'>Es esta la dirección correcta ?</Title>
        { data?.address &&
        <div className='modalRegisters_map'>
            <Maps lat={data.address?.lat} lng={data.address?.lng} />
        </div>
        }
        <Text>{ data?.formatted_address }</Text>

        <div className='modalRegisters_btns'>
            <Button pending={loading} rounded onClick={onSubmit}>
                Si, Correcto
            </Button>
            <Button
                onClick={goBack}
                color='grey'
                textColor='gray'
                transparent
                underline
                disabled={loading}
            >
                Arreglar dirección
            </Button>
        </div>
    </>
)

StepTwo.propTypes = {
    onSubmit: PropTypes.func,
    loading: PropTypes.bool, 
    data: PropTypes.object,
    goBack: PropTypes.func,
}

export default StepTwo
