import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Title } from '../../../../components/Title'
import { RenderMarkDown } from '../../../../components/RenderMarkDown'

// Constants
import { SOCIAL_MEDIA } from '../../../../helpers/constants/socialMedia'

const SocialMedia = ({ title, description, image }) => {
    return (
        <div className='container bg_grey mt5'>
            <div className='content socialMedia'>
                <div className='socialMedia_left'>
                    <Title size='lg'>{title}</Title>
                    <RenderMarkDown>{description}</RenderMarkDown>

                    <div className='socialMedia_list'>
                        {SOCIAL_MEDIA.map((item) => (
                            <div key={item.name} className='socialMedia_list_item'>
                                <a href={item.url} target='_blank' rel='noreferrer'>
                                    {item.icon}
                                </a>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='socialMedia_right'>
                    <img src={image} alt='phone' />
                </div>
            </div>
        </div>
    )
}

SocialMedia.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
}

export default SocialMedia
