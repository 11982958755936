import React from 'react'

// Const
import { URL_ELEMENTAL } from '../../const/urlElemental'

// IMAGES
import LogoWhite from '../../assets/images/logoElementalWhite.svg'

const LogoElemental = () => {
    return (
        <a href={URL_ELEMENTAL} target='_blank' rel='noreferrer'>
            <img src={LogoWhite} alt='Logo Elemental' />
        </a>
    )
}

export default LogoElemental
