import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

// Components
import { Text } from '../Text'
import { Select, Option } from '../Select'

const Pagination = ({ page, countPage, onChangePage, className }) => {
    const allPages = new Array(countPage).fill('')

    const classPagination = clsx('pagination', {
        [className]: className,
    })

    return (
        <div className={classPagination}>
            {page > 1 ? (
                <div
                    className='pagination_btn'
                    onClick={() => onChangePage({ page: page - 1 })}
                >
                    <Text bold underline pointer>
            Anterior
                    </Text>
                </div>
            ) : (
                <div className='pagination_btn' />
            )}

            <div className='pagination_pages'>
                <Text>Página:</Text>
                <Select
                    value={page}
                    name='page'
                    onChange={(e) => onChangePage({ page: e.target.value })}
                >
                    {allPages.map((item, index) => (
                        <Option key={index} value={index + 1}>
                            {index + 1}
                        </Option>
                    ))}
                </Select>
                <Text>de {countPage}</Text>
            </div>

            {page !== countPage ? (
                <div
                    className='pagination_btn'
                    onClick={() => onChangePage({ page: page + 1 })}
                >
                    <Text bold underline pointer>
            Siguiente
                    </Text>
                </div>
            ) : (
                <div className='pagination_btn' />
            )}
        </div>
    )
}

Pagination.propTypes = { 
    page: PropTypes.number, 
    countPage: PropTypes.number, 
    onChangePage: PropTypes.func, 
    className: PropTypes.string, 
}

export default Pagination
