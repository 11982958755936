import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import PropTypes from 'prop-types'

// Routes
import {
    LIST_ROUTER,
    NAVIGATE_DASHBOARD,
} from '../../../../helpers/constants/routes'

const NavigateDashborad = ({ isMobile }) => {
    const location = useLocation()
    const navigate = useNavigate()

    const handleChange = (e) => {
        navigate(e.value)
    }

    const getSelectDefault = () => {
        const item = LIST_ROUTER[NAVIGATE_DASHBOARD].find(
            (item) => item.path === location.pathname,
        )
        return {
            value: item.path,
            label: item.name,
        }
    }

    return (
        <>
            {!isMobile ? (
                <div className='navigateDashboard'>
                    {LIST_ROUTER[NAVIGATE_DASHBOARD].map((route) => (
                        <Link
                            key={route.path}
                            to={route.path}
                            className={
                                location.pathname === route.path
                                    ? 'navigateDashboard_active'
                                    : 'navigateDashboard_item'
                            }
                        >
                            {route.name}
                        </Link>
                    ))}
                </div>
            ) : (
                <Select
                    onChange={handleChange}
                    value={getSelectDefault()}
                    classNamePrefix='navigateDashboardMobile_item'
                    options={LIST_ROUTER[NAVIGATE_DASHBOARD].map((item) => ({
                        value: item.path,
                        label: item.name,
                    }))}
                />
            )}
        </>
    )
}

NavigateDashborad.propTypes = {
    isMobile: PropTypes.bool,
}

export default NavigateDashborad
