import React, { useEffect } from 'react'

// Components
import { RenderPolicy } from '../components/RenderPolicy'

// Hooks
import { usePrivacyPolicy } from '../../../hooks'

// Helpers
import { scrollTop } from '../../../helpers/utils'

const PrivacyPolicy = () => {
    const { dataPrivacyPolicy, loadingDataPrivacyPolicy, getDataPrivacyPolicy } =
    usePrivacyPolicy()

    useEffect(() => {
        (async () => {
            scrollTop()
            await getDataPrivacyPolicy()
        })()
    }, [])

    return (
        <RenderPolicy
            title={dataPrivacyPolicy.Titulo}
            content={dataPrivacyPolicy.Contenido}
            loading={loadingDataPrivacyPolicy}
        />
    )
}

export default PrivacyPolicy
