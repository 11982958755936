/* eslint-disable */
// ICONS
import {
    InstagramIcon,
    FacebookIcon,
    WhatsappIcon,
    YoutubeIcon,
    TiktokIcon,
} from '../../components/Svg'

// Const
import { PHONE_NUMBER } from './contact'

export const SOCIAL_WA = {
    icon: <WhatsappIcon />,
    name: 'Whatsapp',
    url: `https://api.whatsapp.com/send?phone=${PHONE_NUMBER}`,
}

export const SOCIAL_MEDIA = [
    {
        icon: <InstagramIcon />,
        name: 'Instagram',
        url: 'https://www.instagram.com/tierrasgp/',
    },
    {
        icon: <FacebookIcon />,
        name: 'Facebook',
        url: 'https://www.facebook.com/Tierrasgp',
    },
    {
        icon: <YoutubeIcon />,
        name: 'Youtube',
        url: 'https://www.youtube.com/channel/UC6C0lCzhNn3Q-BSlPIXxsdg/videos',
    },
    {
        icon: <TiktokIcon />,
        name: 'Tiktok',
        url: 'https://www.tiktok.com/@sebasvelez_realtor',
    },

    SOCIAL_WA,
]
